import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import LinkList from './LinkList';
import Home from '../Assets/Icons/Home.svg';
import UploadImaging from '../Assets/Icons/UploadImaging.svg';
import UploadVideos from '../Assets/Icons/UploadVideos.svg';
import ViewVideos from '../Assets/Icons/ViewVideos.svg';
import ViewImaging from '../Assets/Icons/ViewImaging.svg';
// import AnnotateVideos from '../Assets/Icons/AnnotateVideos.svg';
// import AnnotateImaging from '../Assets/Icons/AnnotateImaging.svg';
import { Context } from '../Utils/UserContext';
import styled from 'styled-components';
import { colors } from '../GlobalColors';

const Container = styled.div`
  border-top: 0.063rem solid ${colors.medGray};
`;

const LinkBox = styled(Link)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
`;

const Title = styled.h4`
  color: ${colors.darkGray};
  font-size: 0.75rem;
  line-height: 1;
  margin-top: 0.625rem;
  ${LinkBox}:hover & {
    color: ${colors.teal};
  }
`;

const ImageIcon = styled.img`
  max-height: 4.375rem;
  @media (max-width: 1275px) {
    width: 3.125rem;
    height: 3.125rem;
  }
  ${LinkBox}:hover & {
    filter: invert(43%) sepia(91%) saturate(7495%) hue-rotate(341deg)
      brightness(94%) contrast(94%);
  }
`;

const QuickLinks = ({ links }) => {
  const { user } = useContext(Context);

  const nav = {
    home: { icon: Home, title: 'Home', to: '/', authorized: true },
    uploadVideos: {
      icon: UploadVideos,
      title: 'Upload Surgical Videos',
      to: '/video/upload',
      authorized:
        user?.roles?.isUploader ||
        user?.roles?.isSupplierAdmin ||
        user?.roles?.isSuperUser,
    },
    uploadImaging: {
      icon: UploadImaging,
      title: 'Upload Medical Imaging',
      to: '/imaging/upload',
      authorized:
        user?.roles?.isUploader ||
        user?.roles?.isSupplierAdmin ||
        user?.roles?.isSuperUser,
    },
    viewVideos: {
      icon: ViewVideos,
      title: 'View Uploaded Data',
      to: '/video/view',
      authorized:
        user?.roles?.isUploader ||
        user?.roles?.isViewer ||
        user?.roles?.isSupplierAdmin ||
        user?.roles?.isSuperUser,
    },
    viewImaging: {
      icon: ViewImaging,
      title: 'View Imaging',
      to: '/imaging/view',
      authorized:
        user?.roles?.isUploader ||
        user?.roles?.isViewer ||
        user?.roles?.isSupplierAdmin ||
        user?.roles?.isSuperUser,
    },
    // annotateVideos: {
    //   icon: AnnotateVideos,
    //   title: 'Annotate Surgical Videos',
    //   to: '/video/annotate',
    //   authorized:
    //     user?.roles?.isAnnotator ||
    //     user?.roles?.isSupplierAdmin ||
    //     user?.roles?.isSuperUser,
    // },
    // annotateImaging: {
    //   icon: AnnotateImaging,
    //   title: 'Annotate Medical Imaging',
    //   to: '/medical-imaging-annotator',
    // authorized:
    //   user?.roles?.isAnnotator ||
    //   user?.roles?.isSupplierAdmin ||
    //   user?.roles?.isSuperUser,
    // },
  };

  return (
    <Container>
      <LinkList title='Quick Links'>
        {links.map((link) => {
          const navLink = nav[link];
          return navLink.authorized ? (
            <LinkBox to={navLink.to} key={navLink.title}>
              <ImageIcon src={navLink.icon} />
              <Title>{navLink.title}</Title>
            </LinkBox>
          ) : null;
        })}
      </LinkList>
    </Container>
  );
};

export default QuickLinks;

import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { colors } from '../GlobalColors';

const LoadingContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${colors.transparentWhite};
  top: 0;
  left: 0;
  z-index: 999;
`;
const LoadingBox = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const Loader = () => {
  return (
    <LoadingContainer>
      <LoadingBox>
        <Spin />
      </LoadingBox>
    </LoadingContainer>
  );
};

export default Loader;

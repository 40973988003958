import React from 'react';
import { Button, Checkbox, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import IconButton from './IconButton';
import styled from 'styled-components';
import { colors } from '../../../GlobalColors';

const CanvasNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 93%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 1;
  margin: 0 auto;
  box-sizing: border-box;
`;

const CheckContainer = styled.div`
  font-size: 0.8rem;
  color: ${colors.darkGray};
  @media screen and (max-width: 1200px) {
    font-size: 0.7rem;
  }
`;

const FooterButton = styled(Button)`
  border: 0.125rem solid ${colors.teal};
  color: ${colors.teal};
  font-weight: 500;
  font-size: 0.75rem;
  min-width: 5.875rem;
  height: 1.625rem;
  padding: 0;
  background-color: ${colors.white};
  box-shadow: none;
  cursor: auto;
  :hover {
    border-color: ${colors.red};
    color: ${colors.red};
  }
`;

export const Footer = ({
  isSubmitButtonEnabled,
  state,
  dispatch,
  fullScreen,
}) => {
  let currentImageIndex = state.selectedImage;
  let activeImage = state.images[currentImageIndex];
  let activeRegions = activeImage.regions.length > 0;
  let historyCache = state.historyCache[activeImage.name];

  let filter = activeImage.filter ? activeImage.filter : null;
  let hasFilter =
    filter &&
    ((filter.brightness && filter.brightness !== 100) ||
      (filter.contrast && filter.contrast !== 1) ||
      (filter.inverse && filter.inverse !== 0));

  return (
    <CanvasNavigation
      style={
        fullScreen
          ? {
              position: 'fixed',
              bottom: '0',
              left: '0',
              right: '0',
            }
          : {}
      }
    >
      <Container>
        <IconButton
          onClick={() => {
            if (!activeRegions) {
              return;
            }
            dispatch({
              type: 'FOOTER_BUTTON_CLICKED',
              buttonName: 'Undo',
            });
          }}
          name='Undo'
          disabled={!activeRegions}
        />

        <IconButton
          onClick={() => {
            const disabled = historyCache
              ? Array.isArray(historyCache) && historyCache.length === 0
              : true;
            if (disabled) {
              return;
            }
            dispatch({
              type: 'FOOTER_BUTTON_CLICKED',
              buttonName: 'redo',
            });
          }}
          name='Redo'
          disabled={
            historyCache
              ? Array.isArray(historyCache) && historyCache.length === 0
              : true
          }
        />

        <Dropdown
          disabled={!activeRegions && !hasFilter}
          overlay={
            <Menu>
              <Menu.Item
                disabled={!activeRegions}
                onClick={() =>
                  dispatch({
                    type: 'FOOTER_BUTTON_CLICKED',
                    buttonName: 'reset-polygons',
                  })
                }
              >
                Polygons
              </Menu.Item>
              <Menu.Item
                disabled={!hasFilter}
                onClick={() =>
                  dispatch({
                    type: 'FOOTER_BUTTON_CLICKED',
                    buttonName: 'reset-filters',
                  })
                }
              >
                Filters
              </Menu.Item>
              <Menu.Item
                disabled={!activeRegions || !hasFilter}
                onClick={() =>
                  dispatch({
                    type: 'FOOTER_BUTTON_CLICKED',
                    buttonName: 'reset-all',
                  })
                }
              >
                All
              </Menu.Item>
            </Menu>
          }
        >
          <FooterButton>
            RESET <DownOutlined />
          </FooterButton>
        </Dropdown>

        <FooterButton
          disabled={!!activeImage.nothingToLabel ? false : !activeRegions}
          onClick={() =>
            dispatch({ type: 'FOOTER_BUTTON_CLICKED', buttonName: 'save' })
          }
        >
          SAVE
        </FooterButton>
        <CheckContainer>
          <Checkbox
            disabled={activeRegions}
            checked={
              activeImage.nothingToLabel && !activeRegions ? true : false
            }
            onChange={() =>
              dispatch({
                type: 'FOOTER_BUTTON_CLICKED',
                buttonName: 'nolabel',
              })
            }
            name='checkedB'
            style={{ marginRight: '0.5rem' }}
          />
          <span>Nothing to label</span>
        </CheckContainer>
        <FooterButton
          onClick={() =>
            dispatch({ type: 'FOOTER_BUTTON_CLICKED', buttonName: 'submit' })
          }
          disabled={!isSubmitButtonEnabled}
        >
          SUBMIT
        </FooterButton>
      </Container>
    </CanvasNavigation>
  );
};

export default Footer;

import React from 'react';
import styled from 'styled-components';
import { colors } from '../GlobalColors';

const DashContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  @media screen and (max-width: 1200px) {
    margin-bottom: 2rem;
  }
`;

const RightCol = styled.div`
  border-left: 0.125rem solid ${colors.teal};
  padding-left: 1.875rem;
  flex: 2;
  margin-left: 1.875rem;
  flex: 2;
  @media screen and (max-width: 1200px) {
    border-left: none;
    border-top: 0.063rem solid ${colors.medGray};
    padding: 1.875rem 0 1.875rem 0;
    margin: 0;
  }
`;

const LayoutColumn = ({ left, right }) => {
  return (
    <DashContainer>
      <LeftCol>{left}</LeftCol>
      <RightCol>{right}</RightCol>
    </DashContainer>
  );
};

export default LayoutColumn;

import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import { Context } from '../../Utils/UserContext';
import { settingsFlag } from '../../Utils/featureFlag';
import { authenticateUser } from '../../Utils/authentication';
import FormLayout from './FormLayout';
import Button from '../../Components/Buttons/SolidButton';
import FormInput from '../../Components/FormInput';
import NewPassword from './NewPassword';
import { FlexCol, CustomLink } from './Components';
import { getCurrentUser } from '../../Utils/axios';

const LoginContainer = ({ history, location }) => {
  const [newPasswordForm, setNewPasswordForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cognitoUser, setCognitoUser] = useState();
  const { loading, setLoading, setUser } = useContext(Context);
  const { from } = location.state || {
    from: {
      pathname: '/',
    },
  };

  const handleNewPassword = (password) => {
    Auth.completeNewPassword(
      cognitoUser, // the Cognito User Object
      password // the new password
    )
      .then((a) => {
        fetchUserProfile();
        setUser(a);
        setCognitoUser(a);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err.message,
          placement: 'topRight',
        });
      });
  };

  const fetchUserProfile = async () => {
    try {
      if (!settingsFlag) return;
      const profile = await getCurrentUser();
      if (profile) setUser(profile);
    } catch (err) {
      notification.error({
        message: 'Error',
        description: err,
        placement: 'topRight',
      });
    } finally {
      notification.success({
        message: 'Successfully logged in!',
        description: 'Redirecting...',
        placement: 'topRight',
        duration: 1.5,
      });

      history.push(from?.pathname);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const user = await Auth.signIn(username, password);
      if (
        user.challengeName &&
        user.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        setCognitoUser(user);
        setNewPasswordForm(true);
      } else {
        await authenticateUser();
        await fetchUserProfile(user);
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description: err.message,
        placement: 'topRight',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormLayout>
      {newPasswordForm ? (
        <NewPassword loading={loading} handleSubmit={handleNewPassword} />
      ) : (
        <form onSubmit={handleSubmit}>
          <FormInput
            name='Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <FormInput
            name='Password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FlexCol>
            <Button loading={loading}>Submit</Button>
            <CustomLink to='/forgot-password'>Forgot password?</CustomLink>
          </FlexCol>
        </form>
      )}
    </FormLayout>
  );
};

export default LoginContainer;

import React from 'react';
import ReactPlayer from 'react-player';
import { CloseCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { colors } from '../GlobalColors';

const ModalOverlay = styled.div`
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.transparentBlack};
`;

const ModalContainer = styled.div`
  z-index: 1000;
  position: relative;
`;

const CloseIcon = styled(CloseCircleOutlined)`
  font-size: 2rem;
  z-index: 9999;
  position: absolute;
  cursor: pointer;
  right: 1rem;
  top: 1rem;
  color: ${colors.teal};
  :hover {
    color: ${colors.red};
  }
`;

const VideoPlayer = ({ src, timeStart, setShowModal }) => {
  return (
      <ModalOverlay>
        <ModalContainer>
          <CloseIcon onClick={() => setShowModal(false)} />
          <ReactPlayer
            url={timeStart ? `${src}#t=${timeStart}` : src}
            playing={true}
            controls={true}
          />
        </ModalContainer>
      </ModalOverlay>
  );
};

export default VideoPlayer;

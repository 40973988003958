import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Login from '../Pages/Authentication/Login';
import ForgotPassword from '../Pages/Authentication/ForgotPassword';
import ChangePassword from '../Pages/Authentication/ChangePassword';
import ResetPassword from '../Pages/Authentication/ResetPassword';
import Mode from '../Pages/Home/ImageOrVideoPrompt';
import Home from '../Pages/Home';
import ProfileLayout from '../Pages/Profile/ProfileLayout';
import UploadVideos from '../Pages/Upload/UploadVideos';
import UploadImaging from '../Pages/Upload/UploadImaging';
import ViewVideos from '../Pages/View/ViewVideos';
import ViewImaging from '../Pages/View/ViewImaging';
import AnnotateOverview from '../annotator/ProjectDashboard/Overview';
import AnnotateJobView from '../annotator/ProjectDashboard/JobView';
import AnnotateTool from '../annotator/AnnotatePage/AnnotateTool';
import PageNotFound from '../Pages/Home/PageNotFound';

const AppRouter = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <PublicRoute exact path='/login' component={Login} />
        <PublicRoute exact path='/forgot-password' component={ForgotPassword} />
        <PublicRoute exact path='/reset-password' component={ResetPassword} />
        <PrivateRoute
          exact
          path='/change-password'
          component={ChangePassword}
          withoutLogo={true}
          hideDropdown={true}
          showHome={false}
        />
        <PrivateRoute exact path='/' component={Mode} withoutLogo={true} showHome={true} />
        <PrivateRoute exact path='/video' component={Home} withoutLogo={true} showHome={true} />
        <PrivateRoute
          exact
          path='/imaging'
          component={Home}
          withoutLogo={true}
          showHome={true}
        />
        <PrivateRoute exact path='/profile' component={ProfileLayout} />
        <PrivateRoute exact path='/video/upload' component={UploadVideos} />
        <PrivateRoute exact path='/imaging/upload' component={UploadImaging} />
        <PrivateRoute exact path='/video/view' component={ViewVideos} />
        <PrivateRoute exact path='/imaging/view' component={ViewImaging} />
        <PrivateRoute path='/medical-imaging-viewer' />
        {/* <PrivateRoute path='/medical-imaging-annotator' />
        <PrivateRoute path='/3d-model-viewer' /> */}
        <PrivateRoute
          exact
          path='/video/annotate'
          component={AnnotateOverview}
        />
        <PrivateRoute
          exact
          path='/video/annotate/:job'
          component={AnnotateJobView}
        />
        <PrivateRoute
          exact
          path='/video/annotate/:job/:asset'
          component={AnnotateTool}
        />
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </Router>
  );
};

export default AppRouter;

import React, { useState } from 'react';
import FormInput from '../../Components/FormInput';
import Button from '../../Components/Buttons/SolidButton';
import { FlexCol } from './Components';

const NewPassword = ({ loading, handleSubmit }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(newPassword);
      }}
    >
      <FormInput
        name='New Password'
        type='password'
        value={newPassword}
        required={true}
        onChange={(e) => setNewPassword(e.target.value)}
        error='Password must contain at least 8 characters'
        showError={newPassword.length !== 0 && newPassword.length < 8}
      />
      <FormInput
        name='Confirm New Password'
        type='password'
        value={confirmPassword}
        required={true}
        onChange={(e) => setConfirmPassword(e.target.value)}
        error='Passwords do not match'
        showError={
          confirmPassword.length !== 0 && newPassword !== confirmPassword
        }
      />
      <FlexCol>
        <Button
          loading={loading}
          disabled={newPassword !== confirmPassword || newPassword.length < 8}
        >
          Submit
        </Button>
      </FlexCol>
    </form>
  );
};

export default NewPassword;

import React from 'react';

const FilterSlider = ({ id, activeImage, onChange }) => {
  const filter = activeImage.filter;
  // const isInverse = filter && filter.inverse && filter.inverse === 100;

  const getDefaultValue = (label) => {
    if (label === 'brightness') {
      return 100;
    }
    if (label === 'contrast') {
      return 1;
    }
  };

  const getMaxValue = (label) => {
    if (label === 'brightness') {
      return 200;
    }
    if (label === 'contrast') {
      return 10;
    }
  };

  const getMinValue = (label) => {
    if (label === 'brightness') {
      return 10;
    }
    if (label === 'contrast') {
      return 1;
    }
  };

  return (
    <input
      type='range'
      step={0}
      min={getMinValue(id)}
      max={getMaxValue(id)}
      value={filter ? filter[id] : getDefaultValue(id)}
      defaultValue={getDefaultValue(id)}
      aria-label={id}
      id={id}
      onChange={onChange}
      // style={
      //   id === 'brightness' && isInverse === true
      //     ? { transform: 'rotateY(180deg)' }
      //     : {}
      // }
    />
  );
};

export default FilterSlider;

import React from 'react';
import UploadScreen from './index';

const UploadImaging = () => {
  return (
    <div>
      <UploadScreen
        title='Upload Medical Imaging'
        // quickLinksArray={['viewImaging', 'annotateImaging', 'home']}
        quickLinksArray={['viewImaging', 'home']}
      />
    </div>
  );
};

export default UploadImaging;

import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Container from './Container';
import Header from './Header';
import FullScreenContainer from './FullScreen';

const ChildContainer = styled.div`
  margin: 1.5rem 2.25rem;
  padding: 1.5rem;
  min-height: 17.5rem;
`;

const Dashboard = ({ pageTitle, children, prev, tools, fullScreen }) => {
  return (
    <>
      {fullScreen ? (
        <FullScreenContainer tools={tools}>{children}</FullScreenContainer>
      ) : (
        <Container>
          <Header pageTitle={pageTitle} prev={prev} tools={tools} />
          <ChildContainer>{children}</ChildContainer>
        </Container>
      )}
    </>
  );
};
export default withRouter(Dashboard);

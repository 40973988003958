import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../GlobalColors';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CustomLink = styled(Link)`
  font-weight: 500;
  font-size: 0.875rem;
  color: ${colors.teal};
  :hover {
    color: ${colors.red};
  }
`;

export const CustomText = styled.p`
  text-align: center;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    margin-bottom: 1rem;
  }
`;

import React from 'react';
import { PlaySquareOutlined } from '@ant-design/icons';
import ViewLayout from './index';

const ViewVideos = () => {
  return (
    <>
      <ViewLayout
        pageTitle='View Uploaded Data'
        prevUrl='/video'
        listIcon={<PlaySquareOutlined style={{ marginRight: '0.5rem' }} />}
        quickLinksArray={['uploadVideos', 'home']}
        // quickLinksArray={['uploadVideos', 'annotateVideos', 'home']}
      />
    </>
  );
};

export default ViewVideos;

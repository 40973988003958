import React from 'react';
import { useHistory } from 'react-router-dom';
import MainLayout from '../../Components/MainLayout';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const Container = styled.div`
  text-align: center;
  margin-top: 10%;
`;

const Header = styled.h1`
  color: ${colors.white};
`;

const BackLink = styled.h2`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 5rem;
  margin: 0 auto;
  align-items: center;
  color: ${colors.white};
  cursor: pointer;
  :hover {
    color: ${colors.red};
  }
`;

const PageNotFound = () => {
  const history = useHistory();

  return (
    <MainLayout>
      <Container>
      <Header>404: Page Not Found</Header>
      <BackLink onClick={() => history.goBack()}>
        <ArrowLeftOutlined style={{ marginRight: '1rem'}}/> Back
      </BackLink>
      </Container>
    </MainLayout>
  );
};

export default PageNotFound;

import React from 'react';
import { Link } from 'react-router-dom';
import { WarningTwoTone } from '@ant-design/icons';
import { colors } from '../GlobalColors';

const NoData = () => {
  return (
    <>
      <WarningTwoTone twoToneColor={colors.teal} />
      <h1>No Data Found</h1>
      <Link to={'/video/annotate'}></Link>
    </>
  );
};

export default NoData;

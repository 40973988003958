import React, { useEffect, useCallback, useContext } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Context } from '../Utils/UserContext';
import MainLayout from '../Components/MainLayout';

// This component needs to be re worked so we can pass props to the component on the routes page if needed.
const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { user } = useContext(Context);

  const authChecker = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then((currentAuthenticatedUser) => {
        if (!currentAuthenticatedUser) {
          rest.history.push('/login');
        }
        if (
          path.includes('upload') &&
          !user?.roles?.isUploader &&
          !user?.roles?.isSupplierAdmin &&
          !user?.roles?.isSuperUser
        ) {
          if (path.includes('video')) {
            rest.history.push('/video');
          } else if (path.includes('imaging')) {
            rest.history.push('/imaging');
          } else {
            rest.history.push('/');
          }
        } else if (
          path.includes('view') &&
          !user?.roles?.isViewer &&
          !user?.roles?.isUploader &&
          !user?.roles?.isSupplierAdmin &&
          !user?.roles?.isSuperUser
        ) {
          if (path.includes('video')) {
            rest.history.push('/video');
          } else if (path.includes('imaging')) {
            rest.history.push('/imaging');
          } else {
            rest.history.push('/');
          }
        } else if (
          path.includes('annotat') &&
          !user?.roles?.isAnnotator &&
          !user?.roles?.isSupplierAdmin &&
          !user?.roles?.isSuperUser
        ) {
          if (path.includes('video')) {
            rest.history.push('/video');
          } else if (path.includes('imaging')) {
            rest.history.push('/imaging');
          } else {
            rest.history.push('/');
          }
        }
      })
      .catch((e) => {
        console.log(e);
        rest.history.push('/login');
      });
  }, [user, path, rest.history]);

  useEffect(() => {
    if (authChecker) authChecker();
  }, [authChecker]);

  const render = (props) =>
    true ? (
      <MainLayout
        withoutLogo={rest.withoutLogo}
        hideDropdown={rest.hideDropdown}
        showHome={rest.showHome}
      >
        <Component {...props} />
      </MainLayout>
    ) : null;

  return <Route path={path} render={render} {...rest} />;
};

export default withRouter(PrivateRoute);

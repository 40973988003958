import React from 'react';
import IconButton from './IconButton';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0;
`;

const Text = styled.p`
  margin: 0;
`;

const ImageSwitcher = (props) => {
  const { dispatch, state, useHistory } = props;
  const currentImageIndex = state.selectedImage;

  const history = useHistory();
  return (
    <Container>
      <IconButton
        name='Prev'
        disabled={currentImageIndex === 0}
        onClick={() => {
          dispatch({
            type: 'FOOTER_BUTTON_CLICKED',
            buttonName: 'Prev',
          });
          if (currentImageIndex > 0) {
            history.push(`${state.images[currentImageIndex - 1].name}`);
          }
        }}
      />
      {<Text>{`${state.selectedImage + 1}/${state.images.length} `}</Text>}
      <IconButton
        name='Next'
        disabled={currentImageIndex === state.images.length - 1}
        onClick={() => {
          dispatch({
            type: 'FOOTER_BUTTON_CLICKED',
            buttonName: 'Next',
          });
          if (currentImageIndex < state.images.length - 1) {
            history.push(`${state.images[currentImageIndex + 1].name}`);
          }
        }}
      />
    </Container>
  );
};

export default ImageSwitcher;

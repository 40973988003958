import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../../Components/Spinner';
import Pagination from '../../../Components/Pagination';
import FilterBar from './FilterBar';
import NoData from './NoData';
import styled from 'styled-components';
import { colors } from '../../../GlobalColors';

const Table = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  table-layout: fixed;
`;

const TableHeader = styled.th`
  padding: 1.5rem 0;
  color: ${colors.medGray};
`;

const TableRow = styled.tr`
  height: 100%;
  border-top: 0.063rem solid ${colors.medGray};
  :last-child {
    border-bottom: 0.063rem solid ${colors.medGray};
  }
`;

const CustomLink = styled(Link)`
  color: ${colors.teal};
  :hover {
    color: ${colors.red};
  }
`;

const ImageTable = ({ jobAssets }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [images, setImages] = useState(null);
  const [pageItems, setPageItems] = useState([]);

  const itemsPerPage = 20;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    if (jobAssets.length) {
      const lastPage = Math.ceil(jobAssets.length / itemsPerPage);
      const items = jobAssets
        ? currentPage === lastPage
          ? jobAssets.slice(indexOfFirstItem)
          : jobAssets.slice(indexOfFirstItem, indexOfLastItem)
        : [];
        
      setPageItems(items);
    }
    setImages(jobAssets);
    
  }, [jobAssets, currentPage, indexOfFirstItem, indexOfLastItem]);

  return !images ? (
    <Spinner />
  ) : !images.length ? (
    <NoData />
  ) : (
    <>
      <Table>
        <thead>
          <tr>
            <TableHeader>Frame</TableHeader>
            <TableHeader>Asset ID</TableHeader>
            <TableHeader>
              <FilterBar
                placeholder='Last Modified'
                options={['Sort By Newest', 'Sort By Oldest']}
                jobAssets={jobAssets}
                setImages={setImages}
                setCurrentPage={setCurrentPage}
              />
            </TableHeader>
            <TableHeader>
              <FilterBar
                placeholder='Status'
                options={['In Progress', 'Submitted']}
                jobAssets={jobAssets}
                setImages={setImages}
                setCurrentPage={setCurrentPage}
              />
            </TableHeader>
            <TableHeader>Labels</TableHeader>
          </tr>
        </thead>
        <tbody>
          {pageItems.map((item) => (
            <TableRow key={item.key}>
              <td>{item.key + 1}</td>
              <td>
                <CustomLink
                  to={`${item.jobName}/${item.name}?key=${item.key + 1}`}
                >
                  {item.name}
                </CustomLink>
              </td>
              <td>{item.date}</td>
              <td
                style={
                  item.status === 'Submitted' ? { fontWeight: 'bold' } : {}
                }
              >
                {item.status}
              </td>
              <td>{item.label}</td>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Pagination
        firstIndex={indexOfFirstItem}
        lastIndex={indexOfLastItem}
        numberItems={images ? images.length : 0}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default ImageTable;

import React, { useState } from 'react';
import { Row, Col } from 'antd';
import {
  UnlockOutlined,
  LockFilled,
  EyeOutlined,
  EyeInvisibleFilled,
  DeleteFilled,
} from '@ant-design/icons';
import styled from 'styled-components';
import { colors } from '../../../GlobalColors';

const LabelText = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  color: ${colors.darkGray};
`;

const ChipContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelColor = styled.div`
  width: 0.688rem;
  height: 0.688rem;
  margin: 0 0.5rem;
  background-color: ${(props) => props.color};
`;

const IconStyle = {
  height: '1.1rem',
  '&:hover': {
    opacity: '0.5',
  },
};

const LabelRow = ({ region, highlighted, onSelect, onChange, onDelete, canDelete }) => {
  const [, changeMouseOver] = useState(false);
  return (
    <div
      onClick={() => onSelect(region)}
      onMouseEnter={() => changeMouseOver(true)}
      onMouseLeave={() => changeMouseOver(false)}
      style={
        highlighted
          ? { backgroundColor: colors.blue, cursor: 'pointer' }
          : { cursor: 'pointer' }
      }
    >
      <Row>
        <Col span={18}>
          <ChipContainer>
            <LabelColor color={region.color || colors.darkGray} />
            <LabelText>{region.cls || ''}</LabelText>
          </ChipContainer>
        </Col>
        <Col span={2}>
          {canDelete ? (
            <DeleteFilled onClick={() => onDelete(region)} style={IconStyle} />
          ) : (
            ''
          )}
        </Col>
        <Col span={2}>
          {region.visible || region.visible === undefined ? (
            <EyeOutlined
              onClick={() =>
                onChange({
                  ...region,
                  visible: false,
                })
              }
              style={IconStyle}
            />
          ) : (
            <EyeInvisibleFilled
              onClick={() => onChange({ ...region, visible: true })}
              style={IconStyle}
            />
          )}
        </Col>
        <Col span={2}>
          {region.locked ? (
            <LockFilled
              onClick={() => {
                onChange({
                  ...region,
                  edit: true,
                  locked: false,
                });
              }}
              style={IconStyle}
            />
          ) : (
            <UnlockOutlined
              onClick={() => {
                onChange({ ...region, locked: true });
              }}
              style={IconStyle}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LabelRow;
import React, { useState, useContext, useEffect } from 'react';
import { Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import Camera from '../../Assets/Icons/Camera.svg';
import ProfileDefault from '../../Assets/Images/ProfileDefault.svg';
import ProgressBar from '../../Components/ProgressBar';
import axios from 'axios';
import { Context } from '../../Utils/UserContext';
import {
  getPresignedPostData,
  getUserProfilePicture,
  updateUserProfilePicture,
} from '../../Utils/axios';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const ImageDiv = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  :hover {
    .icon {
      filter: invert(43%) sepia(91%) saturate(7495%) hue-rotate(341deg)
        brightness(94%) contrast(94%);
    }

    div p {
      color: ${colors.red};
    }
  }
`;

const ProfileImage = styled.img`
  width: 16rem;
  border-radius: 50%;
  border: 0.125rem solid ${colors.teal};
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  z-index: 999;
  @media (max-width: 450px) {
    width: 10rem;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 5.3rem;
  top: 5.3rem;
  @media (max-width: 450px) {
    left: 2.3rem;
    top: 2.3rem;
  }
`;

const Icon = styled.img`
  z-index: 1000;
`;

const Text = styled.p`
  z-index: 1000;
  color: ${colors.teal};
  font-weight: bold;
`;

const UploadProfilePic = () => {
  const { user, userImage, setUser, setUserImage } = useContext(Context);
  const [activeUpload, setActiveUpload] = useState();
  const [croppedImage, setCroppedImage] = useState();

  useEffect(() => {
    getUserProfilePicture().then((res) => {
      setUserImage(res ?? ProfileDefault);
    });
  }, [setUserImage]);

  const handleUpload = async () => {
    try {
      const {
        data: { data },
        status,
      } = await getPresignedPostData(croppedImage);
      if (status === 200) {
        await uploadFileToS3(data.presignedPostData);
      }
    } catch (err) {
      console.log(err, 'ERR');
    }
  };

  const uploadFileToS3 = (presignedPostData) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'PUT',
        url: presignedPostData.url,
        data: croppedImage,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = progressEvent.loaded / progressEvent.total;
          const progress = Math.round(percentCompleted * 100);
          setActiveUpload({ name: croppedImage.name, progress: progress });
        },
      })
        .then(async (data) => {
          resolve(data);
          const { data: updatedUser } = await updateUserProfilePicture(
            user.id,
            croppedImage.name
          );
          if (updatedUser) {
            setUser(updatedUser);
            setUserImage(updatedUser.profilePictureUrl);
          }
          message.success(`${croppedImage.name} uploaded successfully.`);
          setActiveUpload();
        })
        .catch((err) => {
          console.log(err, 'ERR');
          reject(err);
        });
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ImgCrop
        rotate
        shape='round'
        modalTitle='Crop Image'
        modalOk='Upload'
        onModalOk={setCroppedImage}
      >
        <Upload customRequest={handleUpload}>
          <ImageDiv>
            <ProfileImage src={userImage} alt='User profile photo' />
            <FlexDiv>
              <Icon className='icon' src={Camera} />
              <Text>
                Add/Edit
                <br />
                Profile Photo
              </Text>
            </FlexDiv>
          </ImageDiv>
        </Upload>
      </ImgCrop>
      {activeUpload && (
        <div style={{ marginTop: '1rem' }}>
          <ProgressBar
            name={activeUpload.name}
            progress={activeUpload.progress}
          />
        </div>
      )}
    </div>
  );
};

export default UploadProfilePic;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AWS from 'aws-sdk';
import 'aws-sdk/clients/sagemaker';
import moment from 'moment';
import useSageMakerJobs from '../Utils/useJobs';
import { splitS3Url, getManifestData } from '../Utils/helpers';
import JobList from './DashComponents/JobList';
import ImageTable from './DashComponents/ImageTable';
import Dashboard from '../../Components/Dashboard';
import LayoutColumn from '../../Components/LayoutColumn';
import { getSagemakerJobsByName } from '../../Utils/axios';

const AnnotateJobView = () => {
  const [assetMeta, setAssetMeta] = useState({});
  let [jobAssets, setJobAssets] = useState([]);
  const jobParams = useParams();
  const [jobs, jobsLoading] = useSageMakerJobs();
  const [isLoading, setIsLoading] = useState(false);
  const [lastAsset, setLastAsset] = useState({});

  useEffect(() => {
    getJobAssets(jobParams);
  }, [jobParams]);

  const prepareData = (listUrl) => {
    let _listUrl = listUrl.split('{"source-ref":"');
    let urlData = [];

    _listUrl.map((list) => {
      let _url = list.split('"}');
      if (_url.length === 2) {
        urlData.push(_url[0]);
      }
      return list;
    });
    return urlData;
  };

  const getS3SignedUrl = async (urlList) => {
    let resourceList = [];
    const s3 = new AWS.S3({ apiVersion: '2020-11-09' });

    urlList.map((url, index) => {
      let bucketKey = splitS3Url(url);
      let params = {
        Bucket: bucketKey.groups.bucket,
        Key: bucketKey.groups.key,
      };
      let signedUrl = s3.getSignedUrl('getObject', params);
      let _name = bucketKey.groups.key.split('/');

      resourceList.push({
        jobName: jobParams.job,
        key: index,
        name: _name.pop(),
        src: signedUrl,
      });
      return url;
    });

    setJobAssets(resourceList);
  };

  const getJobManifest = async (bucketUrl) => {
    const s3 = new AWS.S3({ apiVersion: '2020-11-09' });

    let s3ObjectUrl = splitS3Url(bucketUrl);
    let params = {
      Bucket: s3ObjectUrl.groups.bucket,
      Key: s3ObjectUrl.groups.key,
    };
    let promise = s3.getSignedUrlPromise('getObject', params);

    await promise
      .then(
        async function (url) {
          let urlData = await getManifestData(url);
          let urlList = prepareData(urlData);
          await getS3SignedUrl(urlList);
        },
        function (err) {
          // TODO Handle Error
        }
      )
      .catch(() => {
        setJobAssets([]);
        notification.error({ message: 'Resource not found.' });
      });
  };

  const getJobAssetMeta = async (outputBucket) => {
    setIsLoading(true);
    setLastAsset({});
    const s3 = new AWS.S3({ apiVersion: '2020-11-09' });

    let jsonObjectUrl = splitS3Url(outputBucket);

    if (jsonObjectUrl) {
      let jsonObjectKey = `${jsonObjectUrl.groups.key}${jobParams.job}/${jobParams.job}-meta.json`;

      let _params = { Bucket: jsonObjectUrl.groups.bucket, Key: jsonObjectKey };

      let jsonSignedUrl = s3.getSignedUrl('getObject', _params);

      await fetch(jsonSignedUrl)
        .then((response) => response.json())
        .then((data) => {
          setAssetMeta(data);
        })
        .catch(() => {
          setIsLoading(false);
          setAssetMeta({});
        });
    }
  };

  const getJobAssets = async (job) => {
    try {
      const { data } = await getSagemakerJobsByName(jobParams.job);
      await getJobManifest(
        data?.InputConfig.DataSource.S3DataSource.ManifestS3Uri
      );
      await getJobAssetMeta(data?.OutputConfig.S3OutputPath);
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: err.message });
    }

    // const sagemaker = new AWS.SageMaker();
    // setIsLoading(true);
    // let params = {
    //   LabelingJobName: job.job /* required */,
    // };

    // await sagemaker.describeLabelingJob(params, async function (err, data) {
    //   if (err) {
    //     // TODO Handle Error
    //     setIsLoading(false);
    //     notification.error({ message: err.message });
    //   } else {
    //     await getJobManifest(
    //       data.InputConfig.DataSource.S3DataSource.ManifestS3Uri
    //     );
    //     await getJobAssetMeta(data.OutputConfig.S3OutputPath);
    //   }
    // });
  };

  useEffect(() => {
    if (assetMeta.hasOwnProperty('lastAsset')) {
      lastAsset[jobParams.job] = assetMeta.lastAsset.name;
      setLastAsset(lastAsset);
    }
    const assets = jobAssets.map((asset) => {
      if (assetMeta.hasOwnProperty(asset.name)) {
        asset.date = moment(assetMeta[asset.name].updatedAt).format('l');
        asset.status = 'Submitted';
        asset.label = assetMeta[asset.name].numberOfLabels;
      } else {
        asset.date = '-';
        asset.status = 'In Progress';
        asset.label = 0;
      }
      return asset;
    });
    setIsLoading(false);
    setJobAssets(assets);
  }, [assetMeta]);

  const LoadingIcon = (
    <Spin
      indicator={
        <LoadingOutlined style={{ fontSize: 24, textAlign: 'center' }} spin />
      }
    />
  );

  return (
    <Dashboard prev={`/video/annotate`} pageTitle='Project Dashboard'>
      <LayoutColumn
        left={
          jobsLoading ? (
            LoadingIcon
          ) : (
            <JobList
              annotateJobs={jobs}
              lastAsset={lastAsset}
              activeJob={jobParams.job}
            />
          )
        }
        right={isLoading ? LoadingIcon : <ImageTable jobAssets={jobAssets} />}
      />
    </Dashboard>
  );
};

export default AnnotateJobView;

import React, { useState } from 'react';
import { Progress, Popconfirm, Row, Col } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Exit from '../Assets/Icons/Exit.svg';
import styled from 'styled-components';
import { colors } from '../GlobalColors';

const Icon = styled.img`
  :hover {
    filter: invert(43%) sepia(91%) saturate(7495%) hue-rotate(341deg)
      brightness(94%) contrast(94%);
  }
`;

const buttonProps = {
  type: 'default',
  style: { color: colors.teal, borderColor: colors.teal },
};

const ProgressBar = ({ name, progress, cancelUpload }) => {
  return (
    <Row>
      <Col span={1}>
        <Popconfirm
          title='Cancel upload?'
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          okText='Confirm'
          cancelText='Exit'
          okButtonProps={buttonProps}
          cancelButtonProps={buttonProps}
          onConfirm={cancelUpload}
        >
          <Icon src={Exit} style={{ color: colors.red }} alt='cancel icon' />
        </Popconfirm>
      </Col>
      <Col span={5} style={{ wordBreak: 'break-word' }}>
        <p>{name}</p>
      </Col>
      <Col span={18}>
        <Progress
          style={{ width: '100%' }}
          strokeColor={colors.teal}
          percent={progress}
          status={progress === 100 ? 'success' : 'active'}
        />
      </Col>
    </Row>
  );
};

export default ProgressBar;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  :hover {
    img {
      filter: invert(43%) sepia(91%) saturate(7495%) hue-rotate(341deg)
        brightness(94%) contrast(94%);
    }
    div {
      color: ${colors.teal};
    }
  }
  @media (max-width:850px) {
    margin: 2rem;
  }
`;

const Icon = styled.img`
  height: 5rem;
  width: auto;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  color: ${colors.darkGray};
  padding-top: 1rem;
  text-align: center;
`;

const Card = ({ card, setShowModal, setModalData }) => {
  const { title, icon, link, modal } = card;
  return (
    <>
      {link ? (
        <Link to={link}>
          <Container>
            <Icon src={icon} alt={`${title}`} />
            <Title>{title}</Title>
          </Container>
        </Link>
      ) : (
        <Container
          onClick={() => {
            if (modal) {
              setShowModal(true);
              setModalData(card);
            }
          }}
        >
          <img height={100} src={icon} alt={`${title}`} />
          <Title>{title}</Title>
        </Container>
      )}
    </>
  );
};

export default Card;

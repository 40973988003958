import { getFileTypeFromUrl } from '../../Utils/getMode';
import { notification } from 'antd';

export const checkDuplicates = (file, allUploads) => {
  let isDuplicate = false;
  let counter = 0;
  while (isDuplicate === false && counter < allUploads.length) {
    if (
      allUploads[counter].fileUrl.split('/').pop() === file.name &&
      allUploads[counter]?.uploadStatus === 'complete'
    ) {
      isDuplicate = true;
      notification.error({
        message: 'Error',
        description: `A file named ${file.name} already exists. Please rename your file before uploading.`,
        placement: 'bottomLeft',
      });
    } else {
      counter++;
    }
  }
  return isDuplicate;
};

const getFileExt = (file) => {
  if (file.name.includes('.')) {
    return file.name.split('.').slice(1).join('.');
  } else {
    return '';
  }
};

export const filterByExtension = (files) => {
  const mode = getFileTypeFromUrl();
  let filesToUpload = [];
  let filesToDiscard = [];
  let dicomCount = 0;
  for (let i = 0; i < files.length; i++) {
    if (getFileExt(files[i]) === 'dcm') {
      dicomCount++;
    }
  }

  if (mode === 'video') {
    filesToUpload = files.filter((f) => f.type.includes('video'));
    filesToDiscard = files.filter((f) => !f.type.includes('video'));
  } else if (mode === 'ct') {
    filesToUpload = files.filter(
      (f) =>
        f.type.includes('zip') ||
        getFileExt(f).includes('nii') ||
        (getFileExt(f).includes('dcm') && dicomCount <= 1)
    );
    filesToDiscard = files.filter(
      (f) =>
        (!f.type.includes('zip') &&
          !getFileExt(f).includes('nii') &&
          !getFileExt(f).includes('dcm')) ||
        (getFileExt(f).includes('dcm') && dicomCount > 1)
    );
  }

  return {
    filesToUpload: filesToUpload,
    filesToDiscard: filesToDiscard,
  };
};

export const getErrorMessage = (file) => {
  const mode = getFileTypeFromUrl();
  let fileExt = getFileExt(file);
  if (mode === 'video') {
    if (fileExt.includes('nii') || fileExt.includes('dcm')) {
      return (
        <>
          Switch to imaging mode from the top right menu to upload medical
          imaging.
        </>
      );
    } else if (file.type.includes('zip')) {
      return <>Please unzip video files before uploading.</>;
    } else {
      return (
        <>
          File format not supported.
          <br />
          Accepted file formats include:
          <br />
          .avi, .m2v, .m4v, .mkv, .mov, .mp4, .wmv
        </>
      );
    }
  } else if (mode === 'ct') {
    if (file.type.includes('video')) {
      return (
        <>
          Switch to video mode from the top right menu to upload surgical
          videos.
        </>
      );
    } else if (file.name.includes('dcm')) {
      return (
        <>
          If uploading multiple dicom (.dcm) files, please zip the files before
          uploading.
        </>
      );
    } else {
      return (
        <>
          File format not supported.
          <br />
          Accepted file formats include:
          <br />
          nifti (.nii), single dicom (.dcm), and zipped folders.
        </>
      );
    }
  }
};

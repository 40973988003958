import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { filterCompletedJobs, getUserJobs } from '../Utils/helpers';
import { getSagemakerJobs } from '../../Utils/axios';
import { notification } from 'antd';

const useSageMakerJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [jobsLoading, setLoading] = useState(true);

  useEffect(() => {
    getSagemakerJobs().then((res) => {
      try {
        const { data: allJobs } = res?.data;
        Auth.currentAuthenticatedUser().then((data) => {
          const job = getUserJobs(data, allJobs);
          filterCompletedJobs([...job])
            .then((activeJobs) => {
              setJobs([...activeJobs]);
            })
            .finally(() => setLoading(false));
        });
      } catch (error) {
        notification.error('error fetching jobs');
      }
    });
  }, []);

  return [jobs, jobsLoading];
};
export default useSageMakerJobs;

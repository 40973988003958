import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import Spinner from '../../Components/Spinner';
import FormLayout from './FormLayout';
import FormInput from '../../Components/FormInput';
import Button from '../../Components/Buttons/SolidButton';
import { CustomLink, CustomText, FlexCol } from './Components';
import { Context } from '../../Utils/UserContext';

const ResetPassword = () => {
  const { loading, setLoading } = useContext(Context);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      setPasswordResetSuccess(true);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
        placement: 'topRight',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <FormLayout>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {passwordResetSuccess ? (
            <FlexCol>
              <CustomText>
                You have successfuly recovered your account.
              </CustomText>
              <CustomLink to='/login'>Back to Login</CustomLink>
            </FlexCol>
          ) : (
            <form onSubmit={handleSubmit}>
              <CustomText>Please enter the code sent via email</CustomText>
              <FormInput
                name='Email'
                value={email}
                required={true}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormInput
                name='Code'
                value={code}
                required={true}
                onChange={(e) => setCode(e.target.value)}
              />
              <FormInput
                name='New Password'
                type='password'
                value={newPassword}
                required={true}
                onChange={(e) => setNewPassword(e.target.value)}
                error='Password must contain at least 8 characters'
                showError={newPassword.length !== 0 && newPassword.length < 8}
              />
              <FormInput
                name='Confirm New Password'
                type='password'
                value={confirmPassword}
                required={true}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error='Passwords do not match'
                showError={
                  confirmPassword.length !== 0 &&
                  newPassword !== confirmPassword
                }
              />
              <FlexCol>
                <Button
                  loading={loading}
                  disabled={
                    email === '' ||
                    code === '' ||
                    newPassword !== confirmPassword ||
                    newPassword.length < 8
                  }
                >
                  Reset Password
                </Button>
                <CustomLink to='/login'>Back to Login</CustomLink>
              </FlexCol>
            </form>
          )}
        </>
      )}
    </FormLayout>
  );
};

export default ResetPassword;

import React from 'react';
import { useHistory } from 'react-router-dom';
import UploadVideos from '../../Assets/Icons/UploadVideos.svg';
import UploadImaging from '../../Assets/Icons/UploadImaging.svg';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const Modal = styled.div`
  margin: 0 auto;
  width: 40rem;
  height: 25rem;
  border-radius: 1rem;
  background-color: ${colors.white};
  box-shadow: ${colors.transparentBlack} 0 0.3rem 1rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  @media (max-width: 768px) {
    width: 80%;
    height: fit-content;
    top: 32%;
  }
`;

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 1.75rem;
  text-align: center;
  margin: 2rem 0;
`;

const Body = styled.div`
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  margin: 1rem;
  padding: 1rem 0;
  width: 15rem;
  height: 15rem;
  background-color: transparent;
  color: ${colors.teal};
  border: 0.2rem solid ${colors.teal};
  box-sizing: border-box;
  border-radius: 0.4rem;
  font-size: 1.5rem;
  cursor: pointer;
  :hover {
    border-color: ${colors.red};
    p {
      color: ${colors.red};
    }
    img {
      filter: invert(43%) sepia(91%) saturate(7495%) hue-rotate(341deg)
        brightness(94%) contrast(94%);
    }
  }
  @media (max-width: 400px) {
    width: 10rem;
    height: 10rem;
    img {
      width: 5rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Prompt = () => {
  const history = useHistory();
  return (
      <Modal>
        <Title>Are you here to work with:</Title>
        <Body>
          <ButtonContainer>
            <Button onClick={() => history.push('/video')}>
              <img src={UploadVideos} alt='surgical videos' />
              <p>Surgical Videos</p>
            </Button>
            <Button onClick={() => history.push('/imaging')}>
              <img src={UploadImaging} alt='medical imaging' />
              <p>Medical Imaging</p>
            </Button>
          </ButtonContainer>
        </Body>
      </Modal>
  );
};

export default Prompt;

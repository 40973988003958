import React from 'react';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const FullScreen = styled.div`
  background-color: ${colors.white};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const FullScreenContainer = ({ children, tools }) => {
  return (
    <FullScreen>
      {tools}
      {children}
    </FullScreen>
  );
};

export default FullScreenContainer;

import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import Spinner from '../../Components/Spinner';
import FormLayout from './FormLayout';
import FormInput from '../../Components/FormInput';
import Button from '../../Components/Buttons/SolidButton';
import { CustomLink, CustomText, FlexCol } from './Components';
import { Context } from '../../Utils/UserContext';

const ForgotPassword = () => {
  const { loading, setLoading } = useContext(Context);
  const [email, setEmail] = useState('');
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await Auth.forgotPassword(email);
      history.push('/reset-password');
    } catch (err) {
      notification.error({
        message: 'Error',
        description: err.message,
        placement: 'topRight',
      });
    } finally {
      setLoading(false);
      setEmail('');
    }
  };

  return (
    <FormLayout>
      {loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit}>
          <CustomText>Enter the email associated with your account.</CustomText>
          <FormInput value={email} onChange={(e) => setEmail(e.target.value)} />
          <FlexCol>
            <Button loading={loading}>Find My Account</Button>
            <CustomLink to='/login'>Back to Login</CustomLink>
          </FlexCol>
        </form>
      )}
    </FormLayout>
  );
};

export default ForgotPassword;

import React from 'react';
import ActiveProjects from '../../../Assets/Icons/ActiveProjects.svg';
import TotalAssets from '../../../Assets/Icons/TotalAssets.svg';
import TotalAssetsLabeled from '../../../Assets/Icons/TotalAssetsLabeled.svg';
import Stat from '../../../Components/Stat';
import LinkList from '../../../Components/LinkList';

const ProjectStats = ({ title, active, total, labeled }) => {
  return (
    <LinkList title={title}>
      <Stat icon={ActiveProjects} count={active}>
        Active Projects
      </Stat>
      <Stat icon={TotalAssets} count={total}>
        Total Assets
      </Stat>
      <Stat icon={TotalAssetsLabeled} count={labeled}>
        Total Assets Labeled
      </Stat>
    </LinkList>
  );
};

export default ProjectStats;

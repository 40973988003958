import React from 'react';
import { getIcon } from '../Icons/IconMap';
import Slider from './Slider';
import styled from 'styled-components';
import { colors } from '../../../GlobalColors';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: auto;
  padding: 0.625rem 0;
  border-bottom: 0.125rem solid ${colors.medGray};
`;

const Tool = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
`;

const RenderButton = ({
  item,
  onClickItem,
  activeImage,
  handleSlide,
  selectedTools,
}) => {
  return (
    <Tool
      key={item.name}
      onClick={() => {
        return item.onClick ? item.onClick : onClickItem(item);
      }}
    >
      {getIcon(item.name, selectedTools.includes(item.name.toLowerCase()))}
      {(item.name === 'brightness' && selectedTools === 'brightness') ||
      (item.name === 'contrast' && selectedTools === 'contrast') ? (
        <Slider
          id={item.name}
          activeImage={activeImage}
          onChange={handleSlide}
        />
      ) : null}
    </Tool>
  );
};

export const Toolbar = ({
  items = [],
  onClickItem,
  selectedTools,
  activeImage,
  width,
  onFilterValueUpdate,
}) => {
  return (
    <Container width={width}>
      {items.map((item) => {
        if (!item.helperText)
          return (
            <RenderButton
              activeImage={activeImage}
              item={item}
              onClickItem={onClickItem}
              selectedTools={selectedTools}
            />
          );
        return (
          <RenderButton
            key={item.name}
            activeImage={activeImage}
            handleSlide={(e) =>
              onFilterValueUpdate({
                name: e.target.ariaLabel,
                value: e.target.value,
              })
            }
            item={item}
            onClickItem={onClickItem}
            selectedTools={selectedTools}
          />
        );
      })}
    </Container>
  );
};
export default Toolbar;

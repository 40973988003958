import React, { createContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';

export const Context = createContext({});

export const Provider = (props) => {
  // Initial values are obtained from the props
  const {
    user: initialUsers = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null,
    // user: initialUsers = {},
    token: initialToken = localStorage.getItem('token'),
    userImage: initialUserImage = localStorage.getItem('userImage'),
    children,
  } = props;
  // Use State to keep the values
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(initialUsers);
  const [token, setToken] = useState(initialToken);
  const [userImage, setUserImage] = useState(initialUserImage);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }

    if (token) {
      localStorage.setItem('token', token);
    } else localStorage.removeItem('token');

    if (userImage) {
      localStorage.setItem('userImage', userImage);
    } else localStorage.removeItem('userImage');
  }, [token, user, userImage]);

  const logout = () => {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then(() => {
        localStorage.clear();
        setToken(null);
        setUser(null);
        setUserImage(null);
        Auth.signOut();
        window.location.reload();
      })
      .catch((err) => notification.error({ message: "Error", description: err }));
  };

  // Make the context object:
  const usersContext = {
    user,
    setUser,
    token,
    setToken,
    logout,
    userImage,
    setUserImage,
    loading,
    setLoading,
  };

  // pass the value in provider and return
  return <Context.Provider value={usersContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;


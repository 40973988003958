import axios from 'axios';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import { getFileTypeFromUrl } from './getMode';
import ProfileDefault from '../Assets/Images/ProfileDefault.svg';

export const getToken = async () => {
  const token = await Auth.currentSession();
  return await token.getIdToken().getJwtToken();
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// 'http://localhost:3000/dev';

// dataSuppliers
export const getDataSuppliers = async () => {
  return axios
    .get('/dataSuppliers')
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

// fileTypes
export const getFileTypes = async () => {
  return axios
    .get('/fileTypes')
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

// procedures
export const getProcedures = () => {
  return axios
    .get('/procedures')
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

// surgicalEventFiles
export const updateFile = async (fileId, progress, status) => {
  return axios
    .put(
      `/surgicalEventFiles/${fileId}`,
      {
        uploadProgress: progress,
        uploadStatus: status,
      },
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .catch((err) => console.log('ERROR: ', err));
};

export const getSurgicalEventFiles = async (
  search,
  status,
  eventId,
  dataSupplierId
) => {
  const mode = getFileTypeFromUrl();
  const searchParam = search ? `&search=${search}` : '';
  const statusParam = `&uploadStatus=${status ?? 'complete'}`;
  const eventParam = eventId ? `&surgicalEventId=${eventId}` : '';
  const dataSupplierParam = dataSupplierId
    ? `&dataSupplierId=${dataSupplierId}`
    : '';
  return axios
    .get(
      `/surgicalEventFiles?type=${mode}${searchParam}${statusParam}${eventParam}${dataSupplierParam}`,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

export const getEventFileById = async (id) => {
  return axios
    .get(`/surgicalEventFiles/${id}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

export const deleteEventFile = async (id) => {
  return axios
    .delete(`/surgicalEventFiles/${id}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      notification.error({
        message: 'Error',
        description: 'File not found',
        placement: 'topRight',
      });
      return;
    });
};

// surgicalEvents
export const getSurgicalEvents = async () => {
  return axios
    .get('/surgicalEvents?dataSupplierId=mine', {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

export const createSurgicalEvent = async (display, patientRef, procId) => {
  return axios
    .post(
      '/surgicalEvents',
      {
        display: display,
        patientReference: patientRef,
        procedureId: procId,
      },
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .catch((err) => console.log('ERROR: ', err));
};

// upload
export const getPresignedPostData = async (image, eventId, fileTypeId) => {
  return axios
    .post(
      '/upload',
      {
        name: image.name,
        surgicalEventId: eventId,
        fileTypeId: fileTypeId,
      },
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .catch((err) => console.log('ERROR: ', err));
};

// users
export const getCurrentUser = async () => {
  return axios
    .get('/users/current', {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log('ERROR: ', err));
};

export const getUserProfilePicture = async () => {
  return axios
    .get('/users/current', {
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => {
      if (res.data.avatarFilename) {
        return res.data.profilePictureUrl;
      } else {
        return ProfileDefault;
      }
    })
    .catch((err) => console.log('ERROR: ', err));
};

export const updateUserInfo = async (user) => {
  const { id, title, firstName, lastName } = user;
  return axios
    .put(
      `/users/${id}`,
      {
        title: title,
        firstName: firstName,
        lastName: lastName,
      },
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .catch((err) => console.log('ERROR: ', err));
};

export const updateUserProfilePicture = async (id, fileName) => {
  return axios
    .put(
      `/users/${id}`,
      {
        avatarFilename: fileName,
      },
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
    .catch((err) => console.log('ERROR: ', err));
};

//sagemaker
export const getSagemakerJobs = async () => {
  return axios
    .get(`/getSageMakerJobs`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .catch((err) => console.log('ERROR: ', err));
};

export const getSagemakerJobsByName = async (jobName) => {
  if (!jobName) {
    throw new Error('No job name found');
  }
  return axios
    .get(`/getSageMakerJob/${jobName}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
    .catch((err) => console.log('ERROR: ', err));
};

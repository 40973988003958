import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styled, { ThemeProvider } from 'styled-components';
import { colors } from '../GlobalColors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const mainTheme = {
  bg: 'transparent',
  co: colors.darkGray,
};

const selectedTheme = {
  bg: colors.teal,
  co: colors.white,
};

const PageButton = styled.button`
  font-size: 1rem;
  border: none;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.bg};
  color: ${(props) => props.theme.co};
`;

const ItemNumberContainer = styled.div`
  display: flex;
  margin: 1rem 0;
  width: 100%;
  justify-content: center;
`;

const ItemNumberText = styled.span`
  color: ${colors.navy};
  font-weight: bold;
  margin: 0 0.5rem;
`;

const Pagination = ({
  firstIndex,
  lastIndex,
  numberItems,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const nextPage = () => {
    if (currentPage !== pageNumbers.length) {
      setCurrentPage((page) => page + 1);
    }
  };

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage((page) => page - 1);
    }
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(numberItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const firstSelected = pageNumbers.slice(0, 4);
  const lastSelected = pageNumbers.slice(
    pageNumbers.length - 4,
    pageNumbers.length
  );
  const notFirstOrLast = pageNumbers.slice(currentPage - 2, currentPage + 1);
  const numPages = Math.ceil(numberItems / itemsPerPage);

  return (
    <>
      {/* If only 0 or 1 items say "0 of 0" or "1 of 1" */}
      {!numberItems || numberItems <= 1 ? (
        <ItemNumberContainer>
          <ItemNumberText>{numberItems === 0 ? 0 : 1}</ItemNumberText> of{' '}
          <ItemNumberText>{numberItems === 0 ? 0 : 1}</ItemNumberText>
        </ItemNumberContainer>
      ) : (
        <>
          {/* If on last page or only one page, last index = number of items */}
          {currentPage === numPages ? (
            <ItemNumberContainer>
              <ItemNumberText>
                {firstIndex + 1 === numberItems ? numberItems : `${firstIndex + 1} - ${numberItems}`}
              </ItemNumberText>{' '}
              of <ItemNumberText>{numberItems}</ItemNumberText>
            </ItemNumberContainer>
          ) : (
            <ItemNumberContainer>
              <ItemNumberText>
                {firstIndex + 1} - {lastIndex}
              </ItemNumberText>{' '}
              of <ItemNumberText>{numberItems}</ItemNumberText>
            </ItemNumberContainer>
          )}
          {/* If only 0 or 1 items, do not show paginator */}
          {numPages > 1 && (
            <Container>
              {/* Previous arrow */}
              <LeftOutlined
                onClick={prevPage}
                style={
                  currentPage === 1
                    ? { color: colors.medGray, cursor: 'default' }
                    : { color: colors.teal, cursor: 'pointer' }
                }
              />
              {/* If there are 5 pages or less, show all pages */}
              {pageNumbers.length <= 5 ? (
                pageNumbers.map((page, i) => (
                  <ThemeProvider
                    key={i}
                    theme={currentPage === page ? selectedTheme : mainTheme}
                  >
                    <PageButton
                      onClick={() => {
                        setCurrentPage(page);
                      }}
                    >
                      {page}
                    </PageButton>
                  </ThemeProvider>
                ))
              ) : // More than 5 pages & current page is between 1 & 3
              // Show pages 1-4, an ellipsis, and the last page
              // Ex: < 1 2 3 4 ... 6 >
              currentPage <= 3 ? (
                <>
                  {firstSelected.map((page, i) => (
                    <ThemeProvider
                      key={i}
                      theme={currentPage === page ? selectedTheme : mainTheme}
                    >
                      <PageButton onClick={() => setCurrentPage(page)}>
                        {page}
                      </PageButton>
                    </ThemeProvider>
                  ))}
                  ...
                  <ThemeProvider theme={mainTheme}>
                    <PageButton
                      onClick={() => setCurrentPage(pageNumbers.length)}
                    >
                      {pageNumbers.length}
                    </PageButton>
                  </ThemeProvider>
                </>
              ) : // More than 5 pages & current page is within last 3
              // Show first page, an ellipsis, and last 4 pages
              // Ex: < 1 ... 3 4 5 6 >
              currentPage > pageNumbers.length - 3 ? (
                <>
                  <ThemeProvider theme={mainTheme}>
                    <PageButton onClick={() => setCurrentPage(1)}>1</PageButton>
                  </ThemeProvider>
                  ...
                  {lastSelected.map((page, i) => (
                    <ThemeProvider
                      key={i}
                      theme={currentPage === page ? selectedTheme : mainTheme}
                    >
                      <PageButton
                        onClick={() => {
                          setCurrentPage(page);
                        }}
                      >
                        {page}
                      </PageButton>
                    </ThemeProvider>
                  ))}
                </>
              ) : (
                // More than 5 pages & current page falls somewhere in the middle
                // Show first page, ellipsis, 3 middle pages, ellipsis, and last page
                // Ex: < 1 ... 3 4 5 ... 7 >
                <>
                  <ThemeProvider theme={mainTheme}>
                    <PageButton onClick={() => setCurrentPage(1)}>1</PageButton>
                  </ThemeProvider>
                  ...
                  {notFirstOrLast.map((page, i) => (
                    <ThemeProvider
                      key={i}
                      theme={currentPage === page ? selectedTheme : mainTheme}
                    >
                      <PageButton
                        onClick={() => {
                          setCurrentPage(page);
                        }}
                      >
                        {page}
                      </PageButton>
                    </ThemeProvider>
                  ))}
                  ...
                  <ThemeProvider theme={mainTheme}>
                    <PageButton
                      onClick={() => setCurrentPage(pageNumbers.length)}
                    >
                      {pageNumbers.length}
                    </PageButton>
                  </ThemeProvider>
                </>
              )}
              {/* Next arrow */}
              <RightOutlined
                onClick={nextPage}
                style={
                  currentPage === pageNumbers.length
                    ? { color: colors.medGray, cursor: 'default' }
                    : { color: colors.teal, cursor: 'pointer' }
                }
              />
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default Pagination;

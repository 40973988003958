import React from 'react';
import ActiveUploads from '../Assets/Icons/ActiveUploads.svg';
import styled from 'styled-components';
import { colors } from '../GlobalColors';

const Container = styled.div`
  width: 100%;
  cursor: ${(props) => (props.disabled ? '' : 'pointer')};
`;

const Box = styled.div`
  border: 0.125rem solid ${colors.teal};
  border-radius: 1.063rem;
  text-align: center;
  flex: 1;
  position: relative;
  font-size: 1.563rem;
  padding: 3.125rem 0 3.125rem 0;
  width: 25vw;
  @media screen and (max-width: 1200px) {
    width: 50vw;
    min-width: 15rem;
    font-size: 1.25rem;
  }
`;
const Icon = styled.img`
  width: 5.625rem;
  margin-top: 8vh;
`;
const Text = styled.p`
  padding: 5vh 0 10vh 0;
`;

const Browse = styled.div`
  bottom: 0;
  background-color: ${colors.teal};
  color: ${colors.navy};
  width: 100%;
  border-radius: 0 0 0.625rem 0.625rem;
  padding: 0.8rem;
  position: absolute;
`;

const UploadBox = ({ disabled }) => {
  return (
    <Container disabled={disabled}>
      <Box>
        <Icon src={ActiveUploads} alt='' />
        <Text>Click or drag files to upload.</Text>
        <Browse>Browse</Browse>
      </Box>
    </Container>
  );
};
export default UploadBox;

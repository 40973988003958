export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  transparentBlack: 'rgba(0, 0, 0, 0.5)',
  transparentWhite: 'rgba(255, 255, 255, 0.4)',
  teal: '#81CDBA',
  green: '#87D068',
  blue: '#5999C9',
  navy: '#3E5294',
  red: '#EA203E',
  lightGray: '#EBEDF0',
  medGray: '#C4C4C4',
  darkGray: '#333333',
};

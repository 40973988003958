import React from 'react';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const TealRadius = styled.div`
  border: 0.125rem solid ${colors.teal};
  border-radius: 1rem;
  background-color: ${colors.white};
  margin-bottom: 5%;
  margin-top: 1.5rem;
  text-align: center;
  position: absolute;
  width: 90%;
  right: 5%;
  left: 5%;
  min-width: 18rem;
`;

const FullScreen = styled.div`
  background-color: ${colors.white};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const DashboardContainer = ({ children, fullScreen }) => {
  return (
    <>
      {fullScreen ? (
        <FullScreen>{children}</FullScreen>
      ) : (
        <TealRadius>{children}</TealRadius>
      )}
    </>
  );
};

export default DashboardContainer;

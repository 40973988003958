import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';

export const authenticateUser = async () => {
  try {
    const token = await Auth.currentSession();
    console.log(token.getIdToken().getJwtToken());
    // set up AWS credentials
    const credentials = await Auth.currentCredentials();
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      credentials,
    });
  } catch (err) {
    // ignore err if user is not authenticated
  }
};

import React from 'react';
import { FileImageOutlined } from '@ant-design/icons';
import ViewLayout from './index';

const ViewImaging = () => {
  return (
    <>
      <ViewLayout
        pageTitle='View Imaging'
        prevUrl='/imaging'
        listIcon={<FileImageOutlined style={{ marginRight: '0.5rem' }} />}
        // quickLinksArray={['uploadImaging', 'annotateImaging', 'home']}
        quickLinksArray={['uploadImaging', 'home']}
      />
    </>
  );
};

export default ViewImaging;

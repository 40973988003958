import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const StyledButton = styled(Button)`
  background-color: ${colors.white};
  color: ${colors.teal};
  border: 0.1rem solid ${colors.teal};
  border-radius: 0.3rem;
  font-size: 0.75rem;
  width: 9.5rem;
  height: 2.25rem;
  cursor: pointer;
  :hover {
    border-color: ${colors.red};
    color: ${colors.red};
  }
`;

const TealBorderButton = ({ type, htmlType, disabled, loading, onClick, children }) => {
  return (
    <StyledButton
      type={type ?? 'submit'}
      htmlType={htmlType ?? 'submit'}
      disabled={disabled ?? false}
      loading={loading ?? false}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default TealBorderButton;

import React from 'react';
import styled from 'styled-components';
import { colors } from '../GlobalColors';

const Title = styled.h4`
  color: ${colors.darkGray};
  font-size: 0.75rem;
  line-height: 1;
  margin-left: 0.625rem;
`;

const Count = styled.div`
  color: ${colors.navy};
  font-size: 3rem;
  line-height: 1;
  margin-left: 0.625rem;
  font-weight: bold;
`;

const ImageIcon = styled.img`
  max-height: 4.375rem;
  @media (max-width: 79.688rem) {
    width: 3.125rem;
  }
`;

const CustomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1.25rem;
`;
const CustomCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Stat = ({ children, icon, count }) => {
  return (
    <div>
      <CustomRow>
        <ImageIcon src={icon} alt={children} />
        <CustomCol>
          <Title>{children}</Title>
          <Count>{count}</Count>
        </CustomCol>
      </CustomRow>
    </div>
  );
};
export default Stat;

import React, { useState, useEffect } from 'react';
import Spinner from '../../Components/Spinner';
import LayoutColumn from '../../Components/LayoutColumn';
import Dashboard from '../../Components/Dashboard';
import QuickLinks from '../../Components/QuickLinks';
import ProjectStats from './DashComponents/ProjectStats';
import JobList from './DashComponents/JobList';
import useSageMakerJobs from '../Utils/useJobs';

const AnnotateOverview = () => {
  const [jobs, jobsLoading] = useSageMakerJobs();
  const [total, setTotal] = useState(0);
  const [labeled, setLabeled] = useState(0);

  const getStats = async () => {
    // TotalLabeled + Unlabeled?
    const totalArray = await jobs.map(
      (j) => j.LabelCounters.FailedNonRetryableError
    );
    const labeledArray = await jobs.map((j) => j.LabelCounters.HumanLabeled);
    const totalAssets = totalArray.reduce((a, b) => a + b, 0);
    const totalLabeled = labeledArray.reduce((a, b) => a + b, 0);
    setTotal(totalAssets);
    setLabeled(totalLabeled);
  };

  useEffect(() => {
    getStats();
  });

  return (
    <Dashboard prev={`/video`} pageTitle='Project Dashboard'>
      {jobsLoading ? (
        <Spinner />
      ) : (
        <LayoutColumn
          left={<JobList annotateJobs={jobs} />}
          right={
            <>
              <ProjectStats
                title='Project Stats'
                active={jobs ? jobs.length : 0}
                total={total}
                labeled={labeled}
              />
              <QuickLinks links={['uploadVideos', 'viewVideos', 'home']} />
            </>
          }
        />
      )}
    </Dashboard>
  );
};

export default AnnotateOverview;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import Home from '../../Assets/Icons/HomeWhite.svg';
import BackgroundImg from '../../Assets/Images/Background.svg';
import LogoWhite from '../../Assets/Images/LogoWhite.svg';
import UsersDropdown from '../Dropdown';
import Logo from './Logo';
import { getFileTypeFromUrl } from '../../Utils/getMode';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const Background = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  background-image: url(${BackgroundImg});
  padding: 2rem;
`;

const RediMindsLogo = styled.img`
  @media (max-width: 768px) {
    width: 10rem;
  }
`;

const HomeDiv = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.white};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const HomeButton = styled.img`
  height: 2rem;
`;

const MainLayout = ({ children, withoutLogo, showHome, hideDropdown }) => {
  const history = useHistory();
  const mode = getFileTypeFromUrl();

  return (
    <Background>
      <Row>
        <Col span={8}>
          <RediMindsLogo src={LogoWhite} alt='Ground Truth Factory logo' />
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          <HomeDiv onClick={() => history.push('/')}>
            <HomeButton src={Home} />
            {mode === 'video'
              ? 'Video Mode'
              : mode === 'ct'
              ? 'Imaging Mode'
              : null}
          </HomeDiv>
        </Col>
        <Col span={8}>{!hideDropdown && <UsersDropdown />}</Col>
      </Row>
      <Logo stacked={withoutLogo} />
      {children}
    </Background>
  );
};

export default MainLayout;

import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const StyledButton = styled(Button)`
  background-color: ${colors.teal};
  color: ${colors.white};
  border: none;
  border-radius: 0.3rem;
  font-size: 1rem;
  width: 9.5rem;
  height: 2.25rem;
  cursor: pointer;
  :hover {
    background-color: ${colors.red};
    color: ${colors.white};
  }
`;

const SolidButton = ({ type, htmlType, disabled, loading, onClick, children }) => {
  return (
    <StyledButton
      type={type ?? 'submit'}
      htmlType={htmlType ?? 'submit'}
      disabled={disabled ?? false}
      loading={loading ?? false}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default SolidButton;

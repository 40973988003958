import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '../../Components/Modal';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const Button = styled.button`
  margin: 0.188rem;
  background-color: ${colors.white};
  color: ${colors.teal};
  border: 0.1rem solid ${colors.teal};
  box-sizing: border-box;
  border-radius: 0.3rem;
  font-size: 0.75rem;
  cursor: pointer;
  width: 6.25rem;
  :hover {
    border-color: ${colors.red};
    color: ${colors.red};
  }
`;

const UploadModal = ({ setShowModal, data }) => {
  const history = useHistory();
  const { title, modalIcon, continueRoute } = data;
  return (
    <Modal
      icon={modalIcon}
      title={title}
      body={
        title === 'Upload Surgical Videos' ? (
          <>
            Only video files (.avi, .m2v, .m4v, .mkv, .mov, .mp4, .wmv)
            <br />
            are supported by this feature.
            <br />
            <br />
            Make sure you have the correct file format.
            <br />
            Thank you.
          </>
        ) : (
          <>
            Only CT scans and zipped folders (.dcm, .nii, .nii.gz, .zip)
            <br />
            are supported by this feature.
            <br />
            <br />
            If uploading multiple dicom (.dcm) files,
            <br />
            please zip the files before uploading.
          </>
        )
      }
      buttons={
        <>
          <Button onClick={() => setShowModal(false)}>Cancel</Button>
          <Button onClick={() => history.push(continueRoute)}>Continue</Button>
        </>
      }
    />
  );
};

export default UploadModal;

import React from 'react';
import styled from 'styled-components';
import { colors } from '../GlobalColors';

const Title = styled.h4`
  color: ${colors.red};
  font-size: 0.875rem;
  font-weight: bold;
  text-align: left;
  margin: 1rem 0;
  @media screen and (max-width: 1200px) {
    text-align: center;
  }
`;

const Title2 = styled.h4`
  color: ${colors.navy};
  font-size: 0.75rem;
  font-weight: bold;
`;

const Heading = ({ children }) => {
  return <Title>{children}</Title>;
};

export const SubHeading = ({ children }) => {
  return <Title2>{children}</Title2>;
};

export default Heading;

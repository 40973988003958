import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { FolderOpenOutlined, CaretRightFilled } from '@ant-design/icons';
import { ReactComponent as FolderClosedIcon } from '../../../Assets/Icons/FolderClosed.svg';
import ListContainer from '../../../Components/FileList/Container';
import ListItem from '../../../Components/FileList/Item';
import styled from 'styled-components';
import { colors } from '../../../GlobalColors';

const ContinueBtn = styled.button`
  border: none;
  color: ${colors.white};
  background-color: ${colors.teal};
  border-radius: 0.25rem;
  padding: 0.25rem;
  padding-right: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  :hover {
    background-color: ${colors.red};
  }
`;

const ListAnnotateJobs = ({ annotateJobs, lastAsset, activeJob }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const itemsPerPage = 6;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const lastPage = Math.ceil(annotateJobs.length / itemsPerPage);
  const pageItems =
    currentPage === lastPage
      ? annotateJobs.slice(indexOfFirstItem)
      : annotateJobs.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    if (activeJob) {
      let jobIndex = annotateJobs.findIndex(
        (job) => job.LabelingJobName === activeJob
      );
      setCurrentPage(Math.ceil((jobIndex + 1) / itemsPerPage));
    }
  }, [activeJob, annotateJobs]);

  const paginationData = {
    firstIndex: indexOfFirstItem,
    lastIndex: indexOfLastItem,
    numberItems: annotateJobs.length,
    itemsPerPage: itemsPerPage,
    currentPage: currentPage,
    setCurrentPage: setCurrentPage,
  };

  return (
    <>
      <ListContainer title='My Annotation Jobs' paginationData={paginationData}>
        {pageItems.length > 0 ? (
          pageItems.map((job, i) => {
            const date =
              job.LastModifiedTime &&
              moment(job.LastModifiedTime).format('D MMM yyyy');
            return (
              <ListItem
                key={i}
                name={job.LabelingJobName}
                onClickFile={() =>
                  history.push(`/video/annotate/${job.LabelingJobName}`)
                }
                icon={
                  job.LabelingJobName === activeJob ? (
                    <FolderOpenOutlined
                      style={{
                        marginRight: '0.313rem',
                        color: colors.red,
                        fontSize: '1.375rem',
                      }}
                    />
                  ) : (
                    <FolderClosedIcon style={{ marginRight: 10 }} />
                  )
                }
                rightContent={
                  lastAsset &&
                  lastAsset.hasOwnProperty(job.LabelingJobName) && (
                    <Link
                      to={`/video/annotate/${job.LabelingJobName}/${
                        lastAsset[job.LabelingJobName]
                      }`}
                    >
                      <ContinueBtn>
                        <CaretRightFilled />
                        Continue
                      </ContinueBtn>
                    </Link>
                  )
                }
                dateText={`Last Active ${date}`}
              />
            );
          })
        ) : (
          <p>No Jobs</p>
        )}
      </ListContainer>
    </>
  );
};

export default ListAnnotateJobs;

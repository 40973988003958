import React from 'react';
import styled from 'styled-components';
import Heading from './Heading';

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  padding: 1.875rem;
  @media screen and (max-width: 38.625rem) {
    flex-direction: column;
  }
`;

const LinkList = ({ children, title }) => {
  return (
    <div>
      <Heading>{title}</Heading>
      <Flex>{children}</Flex>
    </div>
  );
};

export default LinkList;

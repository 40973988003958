import React from 'react';
import Heading from '../../Components/Heading';
import Pagination from '../Pagination';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
  word-wrap: break-word;
  width: 25vw;
  @media screen and (max-width: 1200px) {
    width: 50vw;
    min-width: 16rem;
  }
`;

const Content = styled.div`
  text-align: left;
  flex-grow: 1;
`;

const ListContainer = ({ title, children, paginationData }) => {
  const {
    firstIndex,
    lastIndex,
    numberItems,
    itemsPerPage,
    currentPage,
    setCurrentPage,
  } = paginationData;

  return (
    <Container>
      <Content>
        <Heading>{title}</Heading>
        {children}
      </Content>
      <Pagination
        firstIndex={firstIndex}
        lastIndex={lastIndex}
        numberItems={numberItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Container>
  );
};

export default ListContainer;

import React from 'react';
import Stat from '../../Components/Stat';
import LinkList from '../../Components/LinkList';
import ProgressBar from '../../Components/ProgressBar';
import ActiveUploads from '../../Assets/Icons/ActiveUploads.svg';
import RecentUploads from '../../Assets/Icons/RecentUploads.svg';
import TotalUploads from '../../Assets/Icons/TotalUploads.svg';

const UploadStats = ({ pageStats, activeUploads, cancelUpload }) => {
  const activeFiles = activeUploads.filter(
    (img) => img.originFileObj.progress !== 100
  );
  return (
    <>
      <LinkList title='Upload Stats'>
        <Stat icon={ActiveUploads} count={activeFiles.length}>
          Active Uploads
        </Stat>
        <Stat icon={RecentUploads} count={pageStats.recent}>
          Recent Uploads
        </Stat>
        <Stat icon={TotalUploads} count={pageStats.total}>
          Total Uploads
        </Stat>
      </LinkList>
      {activeFiles &&
        activeFiles.map((file, i) => (
          <ProgressBar
            key={i}
            name={file.name}
            progress={file.originFileObj.progress}
            cancelUpload={() => cancelUpload(file)}
          />
        ))}
    </>
  );
};

export default UploadStats;

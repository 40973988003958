import React, { useState, useEffect } from 'react';
import { Select, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import EventForm from './EventForm';
import { getSurgicalEvents } from '../../Utils/axios';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const StyledSelect = styled(Select)`
  width: 25vw;
  @media screen and (max-width: 1200px) {
    width: 50vw;
    min-width: 16rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const AddButton = styled.div`
  flex: none;
  padding: 0.5rem;
  cursor: pointer;
  color: ${colors.white};
  background-color: ${colors.teal};
  width: 5rem;
  text-align: center;
`;

const EventDropdown = ({ selectedEventId, setSelectedEventId }) => {
  const [showModal, setShowModal] = useState(false);
  const [surgicalEvents, setSurgicalEvents] = useState([]);

  useEffect(() => {
    getSurgicalEvents().then((res) => {
      setSurgicalEvents(res?.data?.reverse() ?? [])
    });
  }, []);

  return (
    <>
      <StyledSelect
        placeholder='Surgical Events'
        value={selectedEventId}
        style={{ width: '25vw', marginTop: '1rem' }}
        onSelect={setSelectedEventId}
        dropdownStyle={showModal ? { display: 'none' } : {}}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '0.25rem 0' }} />
            <ButtonContainer>
              <AddButton onClick={() => setShowModal(true)}>
                <PlusOutlined /> New
              </AddButton>
            </ButtonContainer>
          </div>
        )}
      >
        {surgicalEvents && surgicalEvents.map((event) => (
          <Select.Option key={event.id} value={event.id}>
            {event.display}
          </Select.Option>
        ))}
      </StyledSelect>
      {showModal && (
        <EventForm
          setShowModal={setShowModal}
          surgicalEvents={surgicalEvents}
          setSurgicalEvents={setSurgicalEvents}
          setSelectedEventId={setSelectedEventId}
        />
      )}
    </>
  );
};

export default EventDropdown;

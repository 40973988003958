import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { colors } from '../../GlobalColors';

const StackedLogo = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 900;
  font-size: 4rem;
  @media (max-height: 650px) {
    flex-direction: row;
    font-size: 2.5rem;
    justify-content: center;
  }
  @media (max-width: 850px) {
    font-size: 2.5rem;
    text-align: center;
    flex-direction: row;
    justify-content: center;
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

const InlineLogo = styled.div`
  font-size: 2.5rem;
  text-align: center;
  font-weight: 900;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 575px) {
    flex-direction: column;
  }
`;

const Heading = styled.div`
  margin-right: ${(props) => props.theme.margin};
  color: ${(props) => props.theme.color};
`;

const whiteTheme = { color: colors.white, margin: '0.625rem' };
const redTheme = { color: colors.red, margin: 0 };

const Logo = ({ stacked }) => {
  return stacked ? (
    <StackedLogo>
      <ThemeProvider theme={whiteTheme}>
        <Heading>Ground Truth</Heading>
      </ThemeProvider>
      <ThemeProvider theme={redTheme}>
        <Heading>Factory</Heading>
      </ThemeProvider>
    </StackedLogo>
  ) : (
    <InlineLogo>
      <ThemeProvider theme={whiteTheme}>
        <Heading>Ground Truth</Heading>
      </ThemeProvider>
      <ThemeProvider theme={redTheme}>
        <Heading>Factory</Heading>
      </ThemeProvider>
    </InlineLogo>
  );
};

export default Logo;

import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { notification } from 'antd';
import FullScreenLoader from './Components/FullScreenLoader';
import AppRouter from './Navigation/Routes';
import { authenticateUser } from './Utils/authentication';
import { Provider } from './Utils/UserContext';
import Amplify, { Auth } from 'aws-amplify';
import awsConfig from './aws-exports';
import './App.less';
Auth.configure(awsConfig);
Amplify.configure(awsConfig);
require('dotenv').config();

function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    authenticateUser().finally(() => {
      setLoading(false);
    });
  }, []);

  const onIdle = async () => {
    try {
      Auth.signOut().then(() => {
        window.location.pathname = process.env.PUBLIC_URL
          ? `${process.env.PUBLIC_URL}/login`
          : '/login';
        localStorage.clear();
      });
    } catch (err) {
      notification.error({
        message: 'Error',
        description: err,
        placement: 'topRight',
      });
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: onIdle,
  });

  return isLoading ? (
    <FullScreenLoader />
  ) : (
    <Provider>
      <AppRouter />
    </Provider>
  );
}

export default App;

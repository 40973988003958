import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import Spinner from '../../Components/Spinner';
import FormLayout from './FormLayout';
import FormInput from '../../Components/FormInput';
import Button from '../../Components/Buttons/SolidButton';
import { ButtonContainer } from './Components';
import { Context } from '../../Utils/UserContext';

const ChangePassword = () => {
  const { loading, setLoading } = useContext(Context);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword, newPassword);
      notification.success({
        message: 'Success',
        description: 'Password changed successfully',
        placement: 'topRight',
      });
      
      history.push('/profile');
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.message,
        placement: 'topRight',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormLayout height='32rem'>
      {loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit}>
          <FormInput
            name='Old Password'
            type='password'
            value={oldPassword}
            required={true}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <FormInput
            name='New Password'
            type='password'
            value={newPassword}
            required={true}
            onChange={(e) => setNewPassword(e.target.value)}
            error='Password must contain at least 8 characters'
            showError={newPassword.length !== 0 && newPassword.length < 8}
          />
          <FormInput
            name='Confirm New Password'
            type='password'
            value={confirmPassword}
            required={true}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error='Passwords do not match'
            showError={
              confirmPassword.length !== 0 && newPassword !== confirmPassword
            }
          />
          <ButtonContainer>
            <Button
              loading={loading}
              disabled={
                newPassword !== confirmPassword || newPassword.length < 8
              }
            >
              Submit
            </Button>
            <Button
              type='button'
              htmlType='button'
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </ButtonContainer>
        </form>
      )}
    </FormLayout>
  );
};

export default ChangePassword;

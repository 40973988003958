import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Popconfirm } from 'antd';
import {
  CloseSquareOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Preview from './Preview';
import Dashboard from '../../Components/Dashboard';
import Spinner from '../../Components/Spinner';
import LayoutColumn from '../../Components/LayoutColumn';
import ListContainer from '../../Components/FileList/Container';
import ListItem from '../../Components/FileList/Item';
import Heading from '../../Components/Heading';
import QuickLinks from '../../Components/QuickLinks';
import VideoPlayer from '../../Components/VideoPlayer';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';
import {
  getDataSuppliers,
  getSurgicalEventFiles,
  getEventFileById,
  getSurgicalEvents,
  deleteEventFile,
} from '../../Utils/axios';
import { getFileTypeFromUrl } from '../../Utils/getMode';
import ToolBar from './ToolBar';
import { Context } from '../../Utils/UserContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const NoUploadsDiv = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  color: ${colors.navy};
  font-weight: bold;
  margin: 5rem 0;
`;

const ViewLayout = ({ pageTitle, listIcon, quickLinksArray }) => {
  const { user } = useContext(Context);
  const [files, setFiles] = useState(null);
  const [recentFiles, setRecentFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [signedUrl, setSignedUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [pageItems, setPageItems] = useState(null);
  const [filter, setFilter] = useState(null);
  const [dataSuppliers, setDataSuppliers] = useState();
  const [surgicalEvents, setSurgicalEvents] = useState();
  const [search, setSearch] = useState('');
  const [showFailed, setShowFailed] = useState(false);
  const mode = getFileTypeFromUrl();

  const getRecentFiles = (allFiles) => {
    if (allFiles && allFiles.length) {
      let all = allFiles;
      all
        .sort((a, b) => {
          return (
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          );
        })
        .reverse();
      if (all && all.length > 3) {
        setRecentFiles(all.slice(0, 3));
      } else {
        setRecentFiles(all);
      }
    } else {
      setRecentFiles([]);
    }
  };

  const itemsPerPage = 6;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginationData = {
    firstIndex: indexOfFirstItem,
    lastIndex: indexOfLastItem,
    numberItems: files?.length ?? 0,
    itemsPerPage: itemsPerPage,
    currentPage: currentPage,
    setCurrentPage: setCurrentPage,
  };

  useEffect(() => {
    getSurgicalEvents().then((res) => {
      setSurgicalEvents(res?.data ?? []);
    });

    if (user?.roles?.isSuperUser) {
      getDataSuppliers().then((res) => {
        setDataSuppliers(res?.data ?? []);
      });
    } else {
      setDataSuppliers(user?.dataSupplier);
    }
  }, [user]);

  const getFiles = useCallback(() => {
    let eventId = null;
    let dataSupplierId = null;
    let status = showFailed ? 'incomplete' : 'complete';

    if (filter) {
      eventId =
        typeof filter === 'number' && !user?.roles?.isSuperUser ? filter : null;
      dataSupplierId =
        (typeof filter === 'number' || filter === 'mine') &&
        user?.roles?.isSuperUser
          ? filter
          : null;
    }

    getSurgicalEventFiles(search, status, eventId, dataSupplierId).then(
      (res) => {
        if (res) {
          let myFiles;
          if (filter && filter === 'my uploads') {
            myFiles = res?.data.filter((f) => f.uploadedById === user.id);
          } else {
            myFiles = res?.data;
          }

          setFiles(myFiles);
          getRecentFiles(myFiles);
        } else {
          setFiles([]);
          setPageItems([]);
        }
      }
    );
  }, [filter, search, user, showFailed]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  useEffect(() => {
    if (files && files.length) {
      const lastPage = Math.ceil(files.length / itemsPerPage);
      const items =
        currentPage === lastPage
          ? files.slice(indexOfFirstItem)
          : files.slice(indexOfFirstItem, indexOfLastItem);
      setPageItems(items);
    } else {
      setPageItems([]);
    }
  }, [files, currentPage, indexOfFirstItem, indexOfLastItem]);

  const handleClick = async (file) => {
    const signed = await getEventFileById(file.id).then((res) => {
      return res?.data?.signedUrl;
    });
    if (mode === 'video') {
      setSignedUrl(signed);
      setShowModal(true);
    } else {
      const fileName = await file.fileUrl.split('/').pop();
      const encodedUrl = await encodeURIComponent(signed);
      window.location = `/medical-imaging-viewer?name=${fileName}&url=${encodedUrl}`;
    }
  };

  const getFileHeader = () => {
    let header;
    if ((!filter && user?.roles?.isSuperUser) || (filter && filter === 'all')) {
      header = `All Uploads`;
    } else if (
      (!filter && !user?.roles?.isSuperUser) ||
      (filter && filter === 'mine')
    ) {
      header = `All ${user?.dataSupplier?.name} Uploads`;
    } else if (filter && filter === 'my uploads') {
      header = 'My Uploads';
    } else if (
      filter &&
      typeof filter === 'number' &&
      !user?.roles?.isSuperUser
    ) {
      let event = surgicalEvents.find((e) => e.id === filter);
      header = `Surgical Event: ${event.display}`;
    } else if (
      filter &&
      typeof filter === 'number' &&
      user?.roles?.isSuperUser
    ) {
      let dataSupplier = dataSuppliers.find((s) => s.id === filter);
      header = `Data Supplier: ${dataSupplier.name}`;
    }
    return `${header}${showFailed ? ' (Incomplete)' : ''}`;
  };

  const deleteFile = (file) => {
    deleteEventFile(file.id)
      .then(() => {
        getFiles();
      })
      .catch((err) => console.log('ERROR: ', err));
  };

  const buttonProps = {
    type: 'default',
    style: { color: colors.teal, borderColor: colors.teal },
  };

  return (
    <Dashboard
      pageTitle={pageTitle}
      prev={mode === 'video' ? '/video' : '/imaging'}
      tools={
        <ToolBar
          isSuperUser={user?.roles?.isSuperUser}
          userSupplier={user?.dataSupplier}
          canFilterByUploader={
            user?.roles?.isViewer || user?.roles?.isSupplierAdmin
          }
          dataSuppliers={dataSuppliers}
          surgicalEvents={surgicalEvents}
          setShowFailed={setShowFailed}
          setFilter={(e) => {
            setFilter(e);
            setCurrentPage(1);
          }}
          onClearFilter={() => {
            setFilter(null);
            setCurrentPage(1);
          }}
          search={search}
          setSearch={setSearch}
          setPageOne={() => setCurrentPage(1)}
        />
      }
    >
      {!files ? (
        <Spinner />
      ) : (
        <LayoutColumn
          left={
            <>
              <ListContainer
                title={getFileHeader()}
                paginationData={paginationData}
              >
                {pageItems && pageItems.length ? (
                  pageItems.map((file) => (
                    <div key={file.id}>
                      <ListItem
                        icon={
                          file.uploadStatus === 'complete' ? (
                            listIcon
                          ) : (
                            <CloseSquareOutlined
                              style={{
                                marginRight: '0.5rem',
                                color: colors.red,
                              }}
                            />
                          )
                        }
                        name={file.fileUrl.split('/').pop()}
                        onClickFile={() => handleClick(file)}
                        rightContent={
                          <Popconfirm
                            title='Delete File?'
                            icon={
                              <QuestionCircleOutlined
                                style={{ color: 'red' }}
                              />
                            }
                            okText='Confirm'
                            cancelText='Exit'
                            okButtonProps={buttonProps}
                            cancelButtonProps={buttonProps}
                            onConfirm={() => deleteFile(file)}
                          >
                            <DeleteOutlined style={{ color: colors.red }} />
                          </Popconfirm>
                        }
                        dateText={
                          file.uploadStatus === 'complete' ? (
                            <>
                              Uploaded {moment(file.updatedAt).format('LL')} by{' '}
                              {file.fileUrl.slice(0, 3)}
                            </>
                          ) : (
                            <div
                              style={{
                                color: colors.red,
                                fontSize: '0.65rem',
                              }}
                            >
                              Status: {file.uploadStatus}, Progress:{' '}
                              {(file.uploadProgress * 100).toFixed(0)}%
                            </div>
                          )
                        }
                      />
                    </div>
                  ))
                ) : (
                  <p style={{ textAlign: 'center' }}>Nothing to Display</p>
                )}
              </ListContainer>
            </>
          }
          right={
            <>
              <Heading>Recently Uploaded</Heading>
              <Container>
                {recentFiles && recentFiles.length ? (
                  recentFiles.map((file) => (
                    <Preview
                      key={file.id}
                      file={file}
                      fileName={file.fileUrl.split('/').pop()}
                      onClick={() => handleClick(file)}
                    />
                  ))
                ) : (
                  <NoUploadsDiv>No Uploads Yet</NoUploadsDiv>
                )}
              </Container>
              <QuickLinks links={quickLinksArray} />
            </>
          }
        />
      )}
      {showModal && <VideoPlayer src={signedUrl} setShowModal={setShowModal} />}
    </Dashboard>
  );
};

export default ViewLayout;

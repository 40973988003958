import React, { useContext } from 'react';
import ProfileForm from './ProfileForm';
import UploadProfilePic from './ProfilePicUpload';
import Dashboard from '../../Components/Dashboard';
import Spinner from '../../Components/Spinner';
import { Context } from '../../Utils/UserContext';

const ProfileLayout = () => {
  const { user } = useContext(Context);

  return (
    <Dashboard pageTitle='Profile' prev='/'>
      {!user ? (
        <Spinner />
      ) : (
        <>
          <UploadProfilePic />
          <ProfileForm />
        </>
      )}
    </Dashboard>
  );
};

export default ProfileLayout;

import React from 'react';
import { Button } from 'antd';
import { ExpandOutlined, CompressOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { colors } from '../../../GlobalColors';

const FullScreenButton = styled(Button)`
  margin: 0 3rem;
  background-color: ${colors.teal};
  color: ${colors.white};
  font-size: 1.5rem;
`;

const FullScreenBtn = ({ fullScreen, setFullScreen }) => {
  const addFullScreen = () => {
    document.getElementsByTagName('body')[0].classList = 'fullScreen';
    setFullScreen(true);
  };

  const removeFullScreen = () => {
    document.getElementsByTagName('body')[0].classList = '';
    setFullScreen(false);
  };

  return (
    <FullScreenButton
      onClick={() => (!fullScreen ? addFullScreen() : removeFullScreen())}
      icon={fullScreen ? <CompressOutlined /> : <ExpandOutlined />}
    />
  );
};

export default FullScreenBtn;

import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import FormInput from '../../Components/FormInput';
import Button from '../../Components/Buttons/BorderButton';
import { updateUserInfo } from '../../Utils/axios';
import { Context } from '../../Utils/UserContext';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5rem 0;
  div {
    margin: 0 1rem;
  }
  &:nth-child(2) {
    border-bottom: 0.1rem solid ${colors.teal};
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonDiv = styled.div`
  button {
    margin: 0 1rem;
  }
`;

const ProfileForm = () => {
  const { user, setUser } = useContext(Context);
  const [title, setTitle] = useState(user.title);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const history = useHistory();

  const updateUser = () => {
    if (firstName === '') {
      notification.error({ message: 'First name cannot be empty' });
      return;
    } else if (lastName === '') {
      notification.error({ message: 'Last name cannot be empty' });
      return;
    } else {
      const newUser = {
        id: user.id,
        title: title,
        firstName: firstName,
        lastName: lastName,
      };
      updateUserInfo(newUser)
        .then((updatedUser) => {
          if (updatedUser) {
            setUser(updatedUser.data);
          }
          notification.success({ message: 'Updated successfully!' });
        })
        .catch((err) => notification.error({ message: err }));
    }
  };

  return (
    <>
      <Section>
        <FormInput
          name='Title'
          value={title}
          required={false}
          onChange={(e) => setTitle(e.target.value)}
        />
        <FormInput
          name='First Name'
          value={firstName}
          required={true}
          onChange={(e) => setFirstName(e.target.value)}
          showError={firstName === ''}
          error={'Required'}
        />
        <FormInput
          name='Last Name'
          value={lastName}
          required={true}
          onChange={(e) => setLastName(e.target.value)}
          showError={lastName === ''}
          error={'Required'}
        />
      </Section>
      <Section>
        <FormInput
          name='Email'
          value={user?.email}
          required={true}
          disabled={true}
        />
        <FormInput
          name='Password'
          value='********'
          required={true}
          disabled={true}
        />
      </Section>
      <ButtonDiv>
        <Button
          onClick={() => {
            history.push('/change-password');
          }}
        >
          Change Password
        </Button>
        <Button onClick={updateUser}>Submit</Button>
      </ButtonDiv>
    </>
  );
};

export default ProfileForm;

import React, { memo, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FolderOpenOutlined, CaretRightOutlined } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';
import Pagination from '../../../Components/Pagination';
import styled, { ThemeProvider } from 'styled-components';
import { colors } from '../../../GlobalColors';

const SideBar = styled.div`
  padding-right: 1.5rem;
`;

const ListHeader = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h4`
  font-size: 0.875rem;
  font-weight: bold;
  padding-left: 0.5rem;
  color: ${colors.darkGray};
`;

const SideList = styled.div`
  margin-bottom: 0;
  padding: 0;
  margin-top: 1.25rem;
`;

const LinkContainer = styled.div`
  width: 60%;
  margin: 0 auto;
`;

const mainTheme = {
  color: colors.teal,
  hoverColor: colors.red,
};

const selectedTheme = {
  color: colors.navy,
  hoverColor: colors.navy,
};

const ImageLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  color: ${(props) => props.theme.color};
  :hover {
    color: ${(props) => props.theme.hoverColor};
  }
`;

const ImageList = ({ images, onSelect, useHistory }) => {
  const history = useHistory();
  const { job, asset } = useParams();
  const [currentPage, setCurrentPage] = useState();

  const itemsPerPage = 20;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const lastPage = Math.ceil(images.length / itemsPerPage);
  const pageItems =
    currentPage === lastPage
      ? images.slice(indexOfFirstItem)
      : images.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    if (asset) {
      // Check if we have given asset in images store
      let imageIndex = images.findIndex((image) => image.name === asset);
      setCurrentPage(Math.ceil((imageIndex + 1) / itemsPerPage));
      if (imageIndex >= 0) {
        // Update current asset to asset matched
        setTimeout(() => {
          onSelect(images[imageIndex], imageIndex);
        }, 0);
      } else {
        // No asset found in images store, fallback to 1st image.
        history.push(images[0].name);
      }
    } else {
      // No asset is given, fallback to 1st image
      history.push(images[0].name);
    }
  }, [asset, history, images, onSelect]);

  return (
    <SideBar>
      <ListHeader>
        <FolderOpenOutlined
          style={{ color: colors.red, fontSize: '1.375rem' }}
        />
        <Title>
          {job}
        </Title>
      </ListHeader>
      <SideList>
        {pageItems.map((image, i) => (
          <LinkContainer key={i}>
            <ThemeProvider
              theme={image.name === asset ? selectedTheme : mainTheme}
            >
              <ImageLink
                to={`/video/annotate/${job}/${image.name}?key=${image.key}`}
                onClick={() => onSelect(image, i)}
              >
                <CaretRightOutlined
                  style={{
                    height: '1.1rem',
                    color: image.name === asset ? colors.navy : colors.white,
                  }}
                />
                Frame {image.key}
              </ImageLink>
            </ThemeProvider>
          </LinkContainer>
        ))}
      </SideList>
      <Pagination
        firstIndex={indexOfFirstItem}
        lastIndex={indexOfLastItem}
        numberItems={images.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </SideBar>
  );
};

const mapUsedImageProps = (a) => [a.name, (a.regions || []).length, a.src];

export default memo(ImageList, (prevProps, nextProps) => {
  return (
    isEqual(
      prevProps.images.map(mapUsedImageProps),
      nextProps.images.map(mapUsedImageProps)
    ) && isEqual(prevProps.state.selectedImage, nextProps.state.selectedImage)
  );
});

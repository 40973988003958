import React from 'react';
import Pan from './Pan.svg';
import ZoomIn from './ZoomIn.svg';
import ZoomOut from './ZoomOut.svg';
import Brightness from './Brightness.svg';
import Contrast from './Contrast.svg';
import Inverse from './Inverse.svg';
import Polygon from './Polygon.svg';
import Draw from './Draw.svg';
import Undo from './Undo.svg';
import Redo from './Redo.svg';
import Previous from './Previous.svg';
import Next from './Next.svg';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { colors } from '../../../GlobalColors';

const ToolIcon = styled(ReactSVG)`
  fill: ${(props) => props.color};
  :hover {
    fill: ${(props) =>
      props.color === colors.teal ? colors.red : props.color};
  }
`;

export const getIcon = (name, selected) => {
  switch (name) {
    // toolbar items
    case 'pan':
      return (
        <ToolIcon src={Pan} color={selected ? colors.navy : colors.teal} />
      );
    case 'zoom-in':
      return (
        <ToolIcon src={ZoomIn} color={selected ? colors.navy : colors.teal} />
      );
    case 'zoom-out':
      return (
        <ToolIcon src={ZoomOut} color={selected ? colors.navy : colors.teal} />
      );
    case 'brightness':
      return (
        <ToolIcon
          src={Brightness}
          color={selected ? colors.navy : colors.teal}
        />
      );
    case 'contrast':
      return (
        <ToolIcon src={Contrast} color={selected ? colors.navy : colors.teal} />
      );
    case 'inverse':
      return (
        <ToolIcon src={Inverse} color={selected ? colors.navy : colors.teal} />
      );
    case 'polygon':
      return (
        <ToolIcon src={Polygon} color={selected ? colors.navy : colors.teal} />
      );
    case 'draw':
      return (
        <ToolIcon src={Draw} color={selected ? colors.navy : colors.teal} />
      );

    // footer items
    case 'Undo':
      return (
        <ToolIcon src={Undo} color={selected ? colors.medGray : colors.teal} />
      );
    case 'Redo':
      return (
        <ToolIcon src={Redo} color={selected ? colors.medGray : colors.teal} />
      );
    case 'Prev':
      return (
        <ToolIcon
          src={Previous}
          color={selected ? colors.medGray : colors.teal}
        />
      );
    case 'Next':
      return (
        <ToolIcon src={Next} color={selected ? colors.medGray : colors.teal} />
      );

    default:
      break;
  }
};

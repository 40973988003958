import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import ArrowLeftTeal from '../../Assets/Icons/ArrowLeftTeal.svg';
import Exit from '../../Assets/Icons/Exit.svg';
import { getFileTypeFromUrl } from '../../Utils/getMode';

const Title = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 1.5rem;
  @media screen and (max-width: 1200px) {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const Icon = styled.img`
  :hover {
    filter: invert(43%) sepia(91%) saturate(7495%) hue-rotate(341deg)
      brightness(94%) contrast(94%);
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ToolBar = styled.div`
  display: flex;
  flex-wrap: no-wrap;
`;

const DashboardHeader = ({ pageTitle, tools, prev, fullScreen }) => {
  const history = useHistory();
  const mode = getFileTypeFromUrl();

  return (
    <Row
      wrap={false}
      style={{ marginTop: '1.5rem', display: 'flex', alignItems: 'center' }}
    >
      <Col flex={1}>
        {fullScreen ? null : (
          <Icon
            src={ArrowLeftTeal}
            onClick={() => (prev ? history.push(prev) : history.goBack())}
          />
        )}
      </Col>
      <Col flex={20}>
        {tools ? (
          <Wrapper>
            <Title>{pageTitle}</Title>
            <ToolBar>{tools}</ToolBar>
          </Wrapper>
        ) : (
          <Title>{pageTitle}</Title>
        )}
      </Col>
      <Col flex={1}>
        {fullScreen ? null : (
          <Icon
            src={Exit}
            onClick={() =>
              history.push(
                mode === 'video'
                  ? '/video'
                  : mode === 'ct'
                  ? '/imaging'
                  : '/'
              )
            }
          />
        )}
      </Col>
    </Row>
  );
};

export default DashboardHeader;

import React, { useState, useContext } from 'react';
import { getFileTypeFromUrl } from '../../Utils/getMode';
import UploadModal from './UploadModal';
import Card from './Card';
import NoData from '../../Components/NoData';
import UploadVideos from '../../Assets/Icons/UploadVideos.svg';
import UploadVideoFile from '../../Assets/Icons/UploadVideoFile.svg';
import UploadImaging from '../../Assets/Icons/UploadImaging.svg';
import UploadImagingFile from '../../Assets/Icons/UploadImagingFile.svg';
import ViewVideos from '../../Assets/Icons/ViewVideos.svg';
import ViewImaging from '../../Assets/Icons/ViewImaging.svg';
import AnnotateVideos from '../../Assets/Icons/AnnotateVideos.svg';
import AnnotateImaging from '../../Assets/Icons/AnnotateImaging.svg';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';
import { Context } from '../../Utils/UserContext';

const Container = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: end;
  border-radius: 0.5rem;
  border: 0.125rem solid ${colors.teal};
  padding: 3rem 0;
  height: max-content;
  width: 90%;
  min-width: 16rem;
  position: absolute;
  left: 5%;
  right: 5%;
  bottom: 20%;
  @media (max-width: 850px) {
    max-width: 25rem;
    flex-direction: column;
    align-items: center;
    position: static;
    margin: 0 auto;
  }
  @media (max-height: 700px) {
    position: static;
    margin: 0 auto;
  }
`;

const NoPermissionsText = styled.div`
  color: ${colors.teal};
  font-size: 1.5rem;
  text-align: center;
`;

const NoPermissionsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UploadVideoCard = {
  icon: UploadVideos,
  title: 'Upload Surgical Videos',
  modal: true,
  modalIcon: UploadVideoFile,
  continueRoute: '/video/upload',
};

const ViewVideoCard = {
  icon: ViewVideos,
  title: 'View Uploaded Data',
  modal: false,
  link: '/video/view',
};

const AnnotateVideoCard = {
  icon: AnnotateVideos,
  title: 'Annotate Surgical Videos',
  modal: false,
  link: '/video/annotate',
};

const UploadImagingCard = {
  icon: UploadImaging,
  title: 'Upload Medical Imaging',
  modal: true,
  modalIcon: UploadImagingFile,
  continueRoute: '/imaging/upload',
};

const ViewImagingCard = {
  icon: ViewImaging,
  title: 'View Imaging',
  modal: false,
  link: '/imaging/view',
};

const AnnotateImagingCard = {
  icon: AnnotateImaging,
  title: 'Annotate Medical Imaging',
  modal: false,
  link: '/medical-imaging-annotator',
};

const Links = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const mode = getFileTypeFromUrl();
  const { user } = useContext(Context);

  return (
    <>
      {showModal && (
        <UploadModal setShowModal={setShowModal} data={modalData} />
      )}
      <Container>
        {!user?.roles?.isUploader &&
          !user?.roles?.isViewer &&
          !user?.roles?.isAnnotator &&
          !user?.roles?.isSupplierAdmin &&
          !user?.roles?.isSuperUser && (
            <NoPermissionsDiv>
              <NoData />
              <NoPermissionsText>
                You don't have any permissions yet.
              </NoPermissionsText>
              <NoPermissionsText>
                Please contact your administrator.
              </NoPermissionsText>
            </NoPermissionsDiv>
          )}
        {(user?.roles?.isUploader ||
          user?.roles?.isSupplierAdmin ||
          user?.roles?.isSuperUser) && (
            <Card
              card={mode === 'video' ? UploadVideoCard : UploadImagingCard}
              setShowModal={setShowModal}
              setModalData={setModalData}
            />
          )}
        {(user?.roles?.isViewer ||
          user?.roles?.isUploader ||
          user?.roles?.isSupplierAdmin ||
          user?.roles?.isSuperUser) && (
            <Card card={mode === 'video' ? ViewVideoCard : ViewImagingCard} />
          )}
        {/* {(user?.roles?.isAnnotator ||
          user?.roles?.isSupplierAdmin ||
          user?.roles?.isSuperUser) &&
          mode === 'video' && <Card card={AnnotateVideoCard} />} */}
        {/* {(user?.roles?.isAnnotator ||
          user?.roles?.isSupplierAdmin ||
          user?.roles?.isSuperUser) && (
          <Card
            card={mode === 'video' ? AnnotateVideoCard : AnnotateImagingCard}
          />
        )} */}
      </Container>
    </>
  );
};

export default Links;

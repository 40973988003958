import React, { useReducer, useEffect } from 'react';
import LabelRow from './LabelRow';
import styled from 'styled-components';
import { colors } from '../../../GlobalColors';

const SectionHeader = styled.h4`
  margin-top: 0.25rem;
  color: ${colors.red};
  font-weight: bold;
  font-size: 0.875rem;
  margin-bottom: 1.25rem;
`;

const Container = styled.div`
  padding: 0 0.938rem;
  padding-bottom: 1.875rem;
  @media (max-width: 1200px) {
    height: 30%;
    overflow: hidden;
    overflow-y: scroll;
  }
`;

const getInitialExpandedState = () => {
  return Boolean(window.__REACT_WORKSPACE_LAYOUT_EXPANDED_STATE);
};

const ObjectSelector = ({
  state,
  onSelectObject,
  onDeleteObject,
  onChangeObject,
  initialExpandedState,
}) => {
  const [expanded] = useReducer(
    (state) => !state,
    initialExpandedState === undefined
      ? getInitialExpandedState()
      : initialExpandedState
  );

  useEffect(() => {
    if (initialExpandedState !== undefined) {
      window.__REACT_WORKSPACE_LAYOUT_EXPANDED_STATE = expanded;
    }
  }, [initialExpandedState, expanded]);

  return (
    <Container>
      <div>
        <SectionHeader>Objects</SectionHeader>
        {state.images[state.selectedImage].regions.map((region) => {
          return (
            <LabelRow
              key={region.id}
              highlighted={region.highlighted}
              region={region}
              onSelect={onSelectObject}
              onChange={onChangeObject}
              onDelete={onDeleteObject}
              canDelete={true}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default ObjectSelector;

import { useMemo } from 'react';
import { getImpliedVideoRegions } from './get-implied-video-regions';

const emptyArr = [];

const useImpliedVideoRegions = (state) => {
  const { keyframes, currentVideoTime = 0 } = state;
  // TODO memoize
  return useMemo(
    () => {
      if (state.annotationType !== 'video') return emptyArr;
      return getImpliedVideoRegions(keyframes, currentVideoTime);
    },
    [keyframes, currentVideoTime, state]
  );
};

export default useImpliedVideoRegions;

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  height: 60%;
  width: 100%;
  overflow: hidden;
`;

const ShadowOverlay = styled.div`
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
`;

const WorkContainer = React.forwardRef(
  ({ children, fullScreen }, ref) => {
    return (
      <Container ref={ref} style={fullScreen ? { height: '90%' } : {}}>
        {children}
        <ShadowOverlay />
      </Container>
    );
  }
);

export default WorkContainer;

import React from 'react';
import { getIcon } from '../Icons/IconMap';
import styled from 'styled-components';

const ImageNavigation = styled.div`
  padding: 0 1rem;
  @media screen and (max-width: 1200px) {
    padding: 0 0.5rem;
  }
`;

const IconButton = ({ name, disabled, onClick }) => {
  return (
    <ImageNavigation onClick={onClick}>
      {getIcon(name, disabled)}
    </ImageNavigation>
  );
};

export default IconButton;

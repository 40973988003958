import React from 'react';
import styled from 'styled-components';
import { colors } from '../GlobalColors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled.label`
  color: ${colors.darkGray};
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
  text-align: left;
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 20rem;
  height: 2.25rem;
  padding-left: 0.5rem;
  border-radius: 0.3rem;
  border: 0.063rem solid ${colors.teal};
  color: ${(props) => (props.disabled ? colors.medGray : colors.black)};
  background: ${(props) => (props.disabled ? colors.lightGray : colors.white)};
  ::placeholder {
    color: ${colors.medGray};
  }
  @media (max-width: 650px) {
    width: 16rem;
  }
`;

const ErrorText = styled.div`
  color: ${colors.red};
`;

const Star = styled.span`
  color: ${colors.red};
`;

const FormInput = ({
  name,
  type,
  placeholder,
  value,
  onChange,
  disabled,
  error,
  showError,
  required
}) => {
  return (
    <Container>
      <InputSection>
        {name && <Label htmlFor={name}><Star>{required ? '* ' : ''}</Star>{name}:</Label>}
        <Input
          name={name ?? ''}
          type={type ?? 'text'}
          placeholder={placeholder ?? null}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        {showError && <ErrorText>{error}</ErrorText>}
      </InputSection>
    </Container>
  );
};

export default FormInput;

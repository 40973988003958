import React from 'react';
import UploadScreen from './index';

const UploadVideos = () => {
  return (
    <div>
      <UploadScreen
        title='Upload Surgical Videos'
        quickLinksArray={['viewVideos', 'home']}
        // quickLinksArray={['viewVideos', 'annotateVideos', 'home']}
      />
    </div>
  );
};

export default UploadVideos;

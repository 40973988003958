import React, { useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import useEventCallback from 'use-event-callback';

export const PreventScrollToParents = ({ children, ...otherProps }) => {
  const [mouseOver, changeMouseOver] = useState(false);
  const onMouseMove = useEventCallback((e) => {
    if (!mouseOver) changeMouseOver(true);
    if (otherProps.onMouseMove) {
      otherProps.onMouseMove(e);
    }
  });
  const onMouseLeave = useEventCallback((e) => {
    setTimeout(() => {
      if (mouseOver) {
        changeMouseOver(false);
      }
    }, 100);
  });

  return (
    <div
      {...otherProps}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      <RemoveScroll enabled={mouseOver} removeScrollBar={false}>
        {children}
      </RemoveScroll>
    </div>
  );
};

export default PreventScrollToParents;

import React, { useState } from 'react';
import { Select } from 'antd';

const FilterBar = ({
  placeholder,
  options,
  jobAssets,
  setImages,
  setCurrentPage,
}) => {
  const [selected, setSelected] = useState();

  const handleChange = (e) => {
    setSelected(e);
    if (e === 'In Progress' || e === 'Submitted') {
      filterByStatus(e);
      setCurrentPage(1);
    } else if (e && e.includes('Sort')) {
      sortByDate(e);
      setCurrentPage(1);
    }
  };

  const filterByStatus = (e) => {
    const res = jobAssets.filter((img) => img.status === e);
    if (!res.length) {
      setImages([]);
    }
    setImages(res);
  };

  const sortByDate = (e) => {
    const hasDate = jobAssets.filter((asset) => asset.date !== '-');
    const noDate = jobAssets.filter((asset) => asset.date === '-');
    if (hasDate.length) {
      const res = hasDate.sort((a, b) => {
        return new Date(a.date) < new Date(b.date)
          ? -1
          : new Date(a.date) > new Date(b.date)
          ? 1
          : 0;
      });
      e.includes('Oldest')
        ? setImages(res.concat(noDate))
        : setImages(res.reverse().concat(noDate));
    } else {
      setImages(jobAssets);
    }
  };

  return (
    <>
      <Select
        allowClear
        placeholder={placeholder}
        onChange={handleChange}
        value={selected}
        bordered={false}
        dropdownMatchSelectWidth={false}
        onClear={() => setImages(jobAssets)}
      >
        {options.map((option, i) => (
          <Select.Option value={option} key={i}>
            {option}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default FilterBar;

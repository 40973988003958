export const getFileTypeFromUrl = () => {
  const mode = window.location.pathname;
  if (mode.includes('video')) {
    return 'video';
  } else if (mode.includes('imaging')) {
    return 'ct';
  } else {
    return '';
  }
}

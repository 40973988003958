import React from 'react';
import styled from 'styled-components';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { colors } from '../GlobalColors';

const Flex = styled.div`
  display: flex;
  flex-wrap: no-wrap;
`;

const InputContainer = styled.form`
  border: 0.125rem solid ${colors.teal};
  display: flex;
  width: 15rem;
  height: 2rem;
  align-items: center;
`;

const SearchInput = styled.input`
  margin-left: 0.25rem;
  width: 100%;
  border: none;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${colors.teal};
  }
`;

const SearchButton = styled.button`
  background-color: ${colors.teal};
  border: none;
  margin-left: 0.5rem;
  height: 2rem;
`;

const SearchBar = ({ placeholder, searchValue, onClick, onChange, onClear, showClear }) => {
  return (
    <Flex>
      <InputContainer>
        <SearchInput
          type='text'
          placeholder={placeholder}
          bordered={false}
          onChange={onChange}
          value={searchValue}
        />
        <CloseCircleOutlined style={{ color: colors.red, display: showClear ? '' : 'none' }} onClick={onClear} />
        <SearchButton
          onClick={onClick}
        >
          <SearchOutlined style={{ color: colors.white }} />
        </SearchButton>
      </InputContainer>
    </Flex>
  );
};

export default SearchBar;

import React, { useState, useEffect } from 'react';
import { message, Select, Form, Input } from 'antd';
import styled from 'styled-components';
import { colors } from '../../GlobalColors';
import { getProcedures, createSurgicalEvent } from '../../Utils/axios';

const Mask = styled.div`
  z-index: 999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.transparentBlack};
`;
const Container = styled.div`
  z-index: 1000;
  position: fixed;
  top: 10rem;
  width: 40rem;
  height: 30rem;
  border-radius: 1rem;
  background-color: ${colors.white};
  box-shadow: ${colors.transparentBlack} 0 0.3rem 1rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;
const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 1.75rem;
  text-align: center;
  margin: 2rem 0;
`;
const Body = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  position: relative;
  font-size: 1rem;
  max-height: 100%;
`;
const Footer = styled.div`
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Button = styled.button`
  margin: 0.188rem;
  background-color: ${colors.white};
  color: ${colors.teal};
  border: 0.1rem solid ${colors.teal};
  box-sizing: border-box;
  border-radius: 0.3rem;
  font-size: 0.75rem;
  cursor: pointer;
  width: 6.25rem;
  :hover {
    border-color: ${colors.red};
    color: ${colors.red};
  }
`;

const EventForm = ({ setShowModal, surgicalEvents, setSurgicalEvents, setSelectedEventId }) => {
  const [newDisplay, setNewDisplay] = useState('');
  const [newPatientRef, setNewPatientRef] = useState('');
  const [selectedProcedure, setSelectedProcedure] = useState('');
  const [procedures, setProcedures] = useState([]);

  useEffect(() => {
    getProcedures().then((res) => setProcedures(res?.data ?? []));
  }, []);

  const addOption = () => {
    let isDuplicate = false;
    let counter = 0;
    while (isDuplicate === false && counter < surgicalEvents.length) {
      if (
        surgicalEvents[counter].display.toLowerCase() ===
        newDisplay.toLowerCase()
      ) {
        isDuplicate = true;
      }
      counter++;
    }

    if (isDuplicate === true) {
      message.error({
        content:
          'This event already exists. Select from existing options or rename event.',
        style: { marginTop: '10vh' },
      });
      return;
    } else if (!newDisplay || newDisplay === '') {
      message.error({
        content: 'Please enter a display name for your procedure',
        style: { marginTop: '10vh' },
      });
    } else if (!selectedProcedure || selectedProcedure === '') {
      message.error({
        content: 'Please select a procedure type from the dropdown',
        style: { marginTop: '10vh' },
      });
    } else {
      createSurgicalEvent(newDisplay, newPatientRef, selectedProcedure).then(res => {
        setSurgicalEvents([...surgicalEvents, res?.data] ?? []);
        setSelectedEventId(res?.data?.id ?? null);
      });
      setNewDisplay('');
      setNewPatientRef('');
      setSelectedProcedure('');
      setShowModal(false);
    }
  };

  return (
    <Mask>
      <Container>
        <Title>New Surgical Event</Title>
        <Body>
          <Form
            layout='vertical'
            style={{ textAlign: 'left' }}
            labelCol={{ span: 16, offset: 4 }}
            wrapperCol={{ span: 16, offset: 4 }}
          >
            <Form.Item
              label='Event Name'
              name='display'
              rules={[{ required: true, message: 'Required' }]}
            >
              <Input
                placeholder='Enter a unique display name'
                onChange={(e) => setNewDisplay(e.target.value)}
              />
            </Form.Item>
            <Form.Item label='Patient Reference' name='patientReference'>
              <Input
                placeholder='Enter a patient identifier (optional)'
                onChange={(e) => setNewPatientRef(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label='Procedure Type'
              name='procedure'
              rules={[{ required: true, message: 'Required' }]}
            >
              <Select
                allowClear
                placeholder='Select a procedure'
                onSelect={(e) => setSelectedProcedure(e)}
              >
                {procedures && procedures.map((proc) => (
                  <Select.Option key={proc.id} value={proc.id}>
                    {proc.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Body>
        <Footer>
          <Button onClick={() => setShowModal(false)}>Cancel</Button>
          <Button onClick={addOption}>Submit</Button>
        </Footer>
      </Container>
    </Mask>
  );
};
export default EventForm;
